import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HelpIcon from "@mui/icons-material/Help";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import moment from "moment";
import { createstdMastersEQPGuide } from "./guide";
const axios = require("axios");
const materialtypeOp = ["Steel", "Sarbide", "Cast Iron","Plastic"];

const mode = ["Source", "Measure"];

const parameters = {
  Source: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Oscilloscope",
    "Temperature Simulation",
    "AC Power @",
    "DC Power",
    "Conductivity meter",
    "pH Meter",
  ],
  Measure: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Temperature Simulation",
    "AC / DC High Voltage",
    "Time",
    "AC / DC High Current (By Direct)",
    "AC / DC High Current (By Clamp)",
  ],
};

const paratype = {
  Source: {
    Oscilloscope: [
      "Amplitude",
      "AC Amplitude @ 50 Hz",
      "DC Amplitude",
      "Time Base",
      "Frequency",
    ],
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD(PT - 100)",
    ],
  },
  Measure: {
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD (PT-100)",
    ],
  },
};

export default function CreateStandard(props) {
  const createstdMastersEQPGuides = createstdMastersEQPGuide();
  const [srfObject, createSrfObject] = React.useState({
    materialtype: "Steel",
  });
  const [standardArray, setStandardArray] = React.useState([]);
  const [image, setImage] = React.useState("");
  const [unitArray, setUnitArray] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [readingRows, setReadingRows] = React.useState([
    ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  ]);
  const [percent, setPercent] = React.useState(false);
  const [plus, setPlus] = React.useState(true);
  const [acc, setAccuracy] = React.useState(false);
  const [mnv, setMnv] = React.useState(true);
  const [readingRangeValue, setReadingRangeValue] = React.useState([
    ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  ]);
  const [location, setLocation] = React.useState("");
  var readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "from_range",
        headerName: "Cal.Lower Point",
        editable: true,
      },
      {
        field: "to_range",
        headerName: "Cal. Higher Point",
        editable: true,
      },
      {
        field: "mode",
        headerName: "Mode",
        editable: true,
        toggle: "plus",
      },
      {
        field: "parameter",
        headerName: "Parameter",
        editable: true,
        toggle: "plus",
      },
      {
        field: "paratype",
        headerName: "Parameter Type",
        editable: true,
        toggle: "plus",
      },
      {
        field: "uncertainty",
        headerName: "Uncertainty",
        editable: true,
      },
      {
        field: "accuracy",
        headerName: "Accuracy",
        editable: true,
      },
      {
        field: "percentOfOutput",
        headerName: "% Of Output",
        editable: true,
        toggle: "plus",
      },
      {
        field: "plusValue",
        headerName: "+ Value",
        editable: true,
        toggle: "plus",
      },
      {
        field: "stability",
        headerName: "Stability",
        editable: true,
      },
      {
        field: "uniformity",
        headerName: "Uniformity",
        editable: true,
      },
      {
        field: "reaction",
        headerName: "Standard Reading",
        editable: true,
      },
      {
        field: "gravity",
        headerName: "Least Count",
        editable: true,
      },
      {
        field: "drift",
        headerName: "Drift",
        editable: true,
      },
      {
        field: "cmv",
        headerName: "Conventional Mass Value",
        editable: true,
        toggle: "mnv",
      },
      {
        field: "mpe",
        headerName: "MPE",
        editable: true,
        toggle: "mnv",
      },
      {
        field: "materialDensity",
        headerName: "Material density",
        editable: true,
        toggle: "mnv",
      },
      {
        field: "Action",
      },
    ],
  ];

  const getUnitMasterArray = () => {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    const newRowss = [...readingRangeValue];
    newRowss[rowIndex][colIndex] = value;
    setReadingRangeValue([...newRowss]);
  };

  const getStandardIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => {
        setStandardArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  // submit form
  const submitDescriptionRows = (readingRows, id) => {
    var rows = [];
    if (percent == false) {
      rows = readingRows.map((row, i) => ({
        standardId: id,
        rangeName: `${row[1]}#${
          readingRangeValue[i][1] != null ? readingRangeValue[i][1] : ""
        }|${row[2]}#${
          readingRangeValue[i][2] != null ? readingRangeValue[i][2] : ""
        }`,
        mode: row[3],
        parameter: row[4],
        paratype: row[5],
        uncertainty:
          readingRangeValue[i][6] != null &&
          readingRangeValue[i][6].length > 0 &&
          row[6] != ""
            ? row[6] + "#" + readingRangeValue[i][6]
            : row[6],
        accuracy:
          readingRangeValue[i][7] != null &&
          readingRangeValue[i][7].length > 0 &&
          row[7] != ""
            ? row[7] + "#" + readingRangeValue[i][7]
            : row[7],
        percentOfOutput:
          readingRangeValue[i][8] != null &&
          readingRangeValue[i][8].length > 0 &&
          row[8] != ""
            ? row[8] + "#" + readingRangeValue[i][8]
            : row[8],
        plusValue:
          readingRangeValue[i][9] != null &&
          readingRangeValue[i][9].length > 0 &&
          row[9] != ""
            ? row[9] + "#" + readingRangeValue[i][9]
            : row[9],
        stability:
          readingRangeValue[i][10] != null &&
          readingRangeValue[i][10].length > 0 &&
          row[10] != ""
            ? row[10] + "#" + readingRangeValue[i][10]
            : row[10],
        uniformity:
          readingRangeValue[i][11] != null &&
          readingRangeValue[i][11].length > 0 &&
          row[11] != ""
            ? row[11] + "#" + readingRangeValue[i][11]
            : row[11],
        reactionTime:
          readingRangeValue[i][12] != null &&
          readingRangeValue[i][12].length > 0 &&
          row[12] != ""
            ? row[12] + "#" + readingRangeValue[i][12]
            : row[12],
        gravity:
          readingRangeValue[i][13] != null &&
          readingRangeValue[i][13].length > 0 &&
          row[13] != ""
            ? row[13] + "#" + readingRangeValue[i][13]
            : row[13],
        drift:
          readingRangeValue[i][14] != null &&
          readingRangeValue[i][14].length > 0 &&
          row[14] != ""
            ? row[14] + "#" + readingRangeValue[i][14]
            : row[14],

        cmv:
          readingRangeValue[i][15] != null &&
          readingRangeValue[i][15].length > 0 &&
          row[15] != ""
            ? row[15] + "#" + readingRangeValue[i][15]
            : row[15],
        mpe:
          readingRangeValue[i][16] != null &&
          readingRangeValue[i][16].length > 0 &&
          row[16] != ""
            ? row[16] + "#" + readingRangeValue[i][16]
            : row[16],
        materialDensity:
          readingRangeValue[i][17] != null &&
          readingRangeValue[i][17].length > 0 &&
          row[17] != ""
            ? row[17] + "#" + readingRangeValue[i][17]
            : row[17],
      }));
    } else {
      rows = readingRows.map((row, i) => ({
        standardId: id,
        rangeName: `${row[1]}#${
          readingRangeValue[i][1] != null ? readingRangeValue[i][1] : ""
        }|${row[2]}#${
          readingRangeValue[i][2] != null ? readingRangeValue[i][2] : ""
        }`,
        mode:
          readingRangeValue[i][3] != null &&
          readingRangeValue[i][3].length > 0 &&
          row[3] != ""
            ? row[3] + "#" + readingRangeValue[i][3]
            : row[3],
        parameter:
          readingRangeValue[i][4] != null &&
          readingRangeValue[i][4].length > 0 &&
          row[4] != ""
            ? row[4] + "#" + readingRangeValue[i][4]
            : row[4],
        paratype:
          readingRangeValue[i][5] != null &&
          readingRangeValue[i][5].length > 0 &&
          row[5] != ""
            ? row[5] + "#" + readingRangeValue[i][5]
            : row[5],
        uncertainty:
          readingRangeValue[i][6] != null &&
          readingRangeValue[i][6].length > 0 &&
          row[6] != ""
            ? row[6] + "#" + readingRangeValue[i][6]
            : row[6],
        accuracy:
          readingRangeValue[i][7] != null &&
          readingRangeValue[i][7].length > 0 &&
          row[7] != ""
            ? row[7] + "#" + readingRangeValue[i][7]
            : row[7],
        stability:
          readingRangeValue[i][10] != null &&
          readingRangeValue[i][10].length > 0 &&
          row[10] != ""
            ? row[10] + "#" + readingRangeValue[i][10]
            : row[10],
        uniformity:
          readingRangeValue[i][11] != null &&
          readingRangeValue[i][11].length > 0 &&
          row[11] != ""
            ? row[11] + "#" + readingRangeValue[i][11]
            : row[11],
        reactionTime:
          readingRangeValue[i][12] != null &&
          readingRangeValue[i][12].length > 0 &&
          row[12] != ""
            ? row[12] + "#" + readingRangeValue[i][12]
            : row[12],
        gravity:
          readingRangeValue[i][13] != null &&
          readingRangeValue[i][13].length > 0 &&
          row[13] != ""
            ? row[13] + "#" + readingRangeValue[i][13]
            : row[13],
        drift:
          readingRangeValue[i][14] != null &&
          readingRangeValue[i][14].length > 0 &&
          row[14] != ""
            ? row[14] + "#" + readingRangeValue[i][14]
            : row[14],
        cmv:
          readingRangeValue[i][15] != null &&
          readingRangeValue[i][15].length > 0 &&
          row[15] != ""
            ? row[15] + "#" + readingRangeValue[i][15]
            : row[15],
        mpe:
          readingRangeValue[i][16] != null &&
          readingRangeValue[i][16].length > 0 &&
          row[16] != ""
            ? row[16] + "#" + readingRangeValue[i][16]
            : row[16],
        materialDensity:
          readingRangeValue[i][17] != null &&
          readingRangeValue[i][17].length > 0 &&
          row[17] != ""
            ? row[17] + "#" + readingRangeValue[i][17]
            : row[17],
      }));
    }
    let url = BASE_URL;
    axios
      .post(url + "standardRanges/bulk", rows)
      .then((res) => {
        toast.success("Standard created successfully!");
        props.setLoader(false);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        props.setLoader(false);
      });
  };

  const handleSubmit = (event) => {
    props.setLoader(true);
    let row = {
      disciplineId: srfObject.disciplineId,
      standardName: srfObject.standardName,
      make: srfObject.make,
      model: srfObject.model,
      certificateNumber: srfObject.certificateNumber,
      traceability: srfObject.traceability,
      calDate: moment(srfObject.calDate).format("YYYY-MM-DD"),
      validUpto: moment(srfObject.validUpto).format("YYYY-MM-DD"),
      stId: srfObject.ids,
      serialNo: srfObject.serialNo,
      type: 1,
      masterrange: srfObject.masterrange,
      masteraccuracy: srfObject.masteraccuracy,
      masterleastcount: srfObject.masterleastcount,
      materialtype:
        srfObject.materialtype === "select material type"
          ? ""
          : srfObject.materialtype,
      location: location,
    };

    let url = BASE_URL;
    axios
      .post(url + "standards", row)
      .then((res) => {
        var insertedID = res.data.insertId;
        submitDescriptionRows(readingRows, insertedID);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        props.setLoader(false);
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    if (tableIndex !== undefined)
      newReadingRows.push([...readingRows[tableIndex]]);
    else
      newReadingRows.push([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);

    setReadingRows([...newReadingRows]);
    let newReadingRowss = [...readingRangeValue];
    if (tableIndex !== undefined)
      newReadingRowss.push([...readingRangeValue[tableIndex]]);
    else
      newReadingRowss.push([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
    setReadingRangeValue([...newReadingRowss]);
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);
  };

  useEffect(() => {
    getStandardIdList();
    getUnitMasterArray();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
       <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Master EQP
      </Typography>
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          createstdMastersEQPGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div> 
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <Autocomplete
            size="small"
            id="create_stdMastersEQP_discipline"
            options={standardArray.map(({ id, name }) => ({
              label: id + ", " + name,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            onInputChange={(event, newInputValue) => {
              let toggle = [true, true, true, true];

              standardArray.forEach((el) => {
                if (el.id == newInputValue.split(",")[0]) {
                  if (el.disciplineKey == "0") {
                    toggle[0] = false;
                    toggle[1] = false;
                  } else if (el.disciplineKey == "2") {
                    toggle[3] = false;
                  }
                }
              });

              const unitArr = [""];
              for (let i = 0; i < unitArray.length; i++) {
                if (
                  parseInt(newInputValue.split(",")[0]) ==
                  unitArray[i].disciplineId
                ) {
                  unitArr.push(unitArray[i]);
                }
              }
              setUnits(unitArr);

              updateSrfObject(
                "disciplineId",
                parseInt(newInputValue.split(",")[0])
              );

              setPercent(toggle[0]);
              setPlus(toggle[1]);
              setMnv(toggle[3]);
            }}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_eqp"
            label="Master EQP Name * "
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("standardName", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_id"
            label="id"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("ids", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_srno"
            label="Sr No. "
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("serialNo", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_make"
            label="Make "
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("make", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_model"
            label="Model "
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("model", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_traceability"
            label="Tracability "
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("certificateNumber", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_calagency"
            label="Cal. Agency. "
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("traceability", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_location"
            label="Location "
            size="small"
            fullWidth
            variant="outlined"
            value={location}
            onChange={(e) => {
              updateSrfObject("location", e.target.value);
              setLocation(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_range"
            label="Range "
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("masterrange", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_accuracy"
            label="Accuracy/Class "
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("masteraccuracy", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="create_stdMastersEQP_master_lc"
            label="Least Count "
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("masterleastcount", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Autocomplete
            size="small"
            id="create_stdMastersEQP_master_materialtype"
            options={materialtypeOp.map((e) => ({
              label: e,
            }))}
            defaultValue={srfObject.materialtype}
            renderInput={(params) => (
              <TextField {...params} label="Material Type " />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject("materialtype", newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2} id="create_stdMastersEQP_master_caldate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="Cal Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={srfObject.calDate ? srfObject.calDate : new Date()}
              onChange={(newValue) => {
                updateSrfObject("calDate", new Date(newValue));
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2} id="create_stdMastersEQP_master_duedate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="Due Dt. *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={srfObject.validUpto ? srfObject.validUpto : new Date()}
              onChange={(newValue) => {
                updateSrfObject("validUpto", new Date(newValue));
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Master Ranges</h4>
      <Button
      id="create_stdMastersEQP_master_addmasterrange"
        style={{ float: "right",marginBottom:"15px" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
        variant="contained"
        size="small"
      >
        add master uncertinity
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0]
                  .filter(
                    (e) =>
                      e.toggle === undefined ||
                      (e.toggle === "plus" && !plus) ||
                      (e.toggle === "mnv" && !mnv)
                  )
                  .map((column) => (
                    <TableCell key={column.field}>
                      {" "}
                      <Typography noWrap>{column.headerName}</Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {index + 1}
                    <Button
                    id="create_stdMastersEQP_master_copymasterrange"
                      onClick={(e) => {
                        addDescriptionRow(index);
                      }}
                    >
                      <ContentCopyIcon />
                    </Button>
                  </TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex !== 3 &&
                      cellIndex !== 4 &&
                      cellIndex !== 5 &&
                      cellIndex !== 7 &&
                      cellIndex !== 0
                    ) {
                      if ((cellIndex === 8 || cellIndex === 9) && plus)
                        return "";
                      if (
                        (cellIndex === 15 ||
                          cellIndex === 16 ||
                          cellIndex === 17) &&
                        mnv
                      )
                        return "";
                      return (
                        <TableCell>
                          <TextField
                          id="create_stdMastersEQP_master_callowerpt"
                            size="small"
                            disabled={acc}
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                          id="create_stdMastersEQP_master_createunit"
                            disabled={acc}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex === 3 && !plus) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "150px" }}
                            size="small"
                            id="combo-box-demo"
                            value={cell}
                            options={mode.map((e) => ({
                              label: e,
                            }))}
                            renderInput={(params) => (
                              <TextField {...params} label="Mode *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue);
                            }}
                          />
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex === 4 && !plus) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "250px" }}
                            size="small"
                            id="combo-box-demo"
                            value={cell}
                            options={
                              parameters[row[3]]
                                ? parameters[row[3]].map((e) => ({
                                    label: e,
                                  }))
                                : []
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Parameter *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue);
                            }}
                            disabled={!row[3] ? true : false}
                          />
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex === 5 && !plus) {
                      return (
                        <TableCell>
                          {!(paratype[row[3]] && paratype[row[3]][row[4]]) ? (
                            <TextField
                              size="small"
                              disabled={acc}
                              value={cell}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            ></TextField>
                          ) : (
                            <Autocomplete
                              style={{ width: "150px" }}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={
                                paratype[row[3]] && paratype[row[3]][row[4]]
                                  ? paratype[row[3]][row[4]].map((e) => ({
                                      label: e,
                                    }))
                                  : []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Parameter Type *"
                                />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue
                                );
                              }}
                            />
                          )}
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex === 7) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            disabled={acc}
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            disabled={acc}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                    id="create_stdMastersEQP_dispatchchalland_delete"
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />
      <Toolbar style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}>
      <Button
          variant="contained"
          style={{backgroundColor:"grey"}}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
        id="create_stdMastersEQP_dispatchchalland_update"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            handleSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
