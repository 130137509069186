import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DatasheetContext } from "../datasheet.context";
import { BASE_URL, MAX_COLS } from "../../../../global";
import axios from "axios";

import {
  UNCERTAINTY_LINK,
  EXTRA_COLUMN_LINK,
} from "../../../master/staticTable/editTable";
import { toast } from "react-toastify";
import {
  Button,
  ButtonGroup,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandCircleDownOutlined from "@mui/icons-material/ExpandCircleDownOutlined";
import AddIcon from "@mui/icons-material/Add";

import { ClassicTable } from "../../../../utils/components/Styles";
import { showComponent } from "../../../helper/helpers";
import StandardMasterSelection from "../StandardMasterSelection";
import {
  fetchCertificateStaticTables,
  fetchDatasheetStaticTables,
  fetchOtherStaticReadingRows,
  fetchStaticReadingRows,
} from "../fetchHandlers";
import { Box } from "@mui/system";

export default function ObservedReadings(props) {
  const { datasheetId, isPrinting } = props;

  const {
    state: {
      datasheetDetails,
      datasheetStaticReadings,
      certificateStaticReadings,
      staticTables,
      certificateStaticTables,
      standardRanges,
      standardMasterArray,
      unitSymbols,
      indicators,
      selectedSupportiveRanges,
      supportiveStandardMasterArray,
      settings,
    },
    dispatch,
  } = useContext(DatasheetContext);

  const [staticReadingRows, setStaticReadingRows] = useState([]);
  const [selectedRangeIds, setSelectedRangeIds] = useState([]);
  const [certificateReadingIds, setCertificateReadingIds] = React.useState({}); // dictionary to map datasheet id with certificate id
  const [derivedColumns, setDerivedColumns] = React.useState([]);
  const [unceraintyConfig, setUnceraintyConfig] = React.useState({});
  const [deletedIds, setDeletedIds] = useState([]);
  const [activeRowIndex, setActiveRowIndex] = useState(-1);
  const [activeTable, setActiveTable] = useState(-1);
  const [precNotEqTracker, setPrecNotEqTracker] = useState({});

  // utility functions
  const getCustomColumnCount = (
    tableId,
    isStatic = null,
    isCertificate = null,
    tables = null
  ) => {
    let tbl = tables;
    if (!tbl) {
      tbl =
        isStatic === "static"
          ? isCertificate === "certificate"
            ? certificateStaticTables
            : staticTables
          : null;
    }

    let count = 0;
    if (!tbl) return count;
    for (let i = 0; i < tbl.length; i++) {
      if (tbl[i].id == tableId) {
        for (let j = 0; j < MAX_COLS; j++) {
          if (tbl[i]["c" + (j + 1)] != null) {
            count++;
          } else {
            break;
          }
        }
      }
    }
    return count;
  };

  const checkMaster = (index) => {
    let rangeId = staticReadingRows[index][staticReadingRows[index].length - 2];
    rangeId =
      rangeId && rangeId !== "" && rangeId?.length > 0
        ? rangeId?.split(",")
        : [];
    rangeId = rangeId.filter((e) => e !== "");

    if (rangeId.length) return true;
    return false;
  };

  const validateSelectedStandardRange = (range, tableId) => {
    // 0. check is table is enabled for out of range validation
    if (
      !settings?.["DS Row: standard master validation"] ||
      settings?.["DS Row: standard master validation"] === "false"
    )
      return;

    console.log("validateSelectedStandardRange: ", range, tableId);

    // 1. validate LC precision count against standard least count
    let lc = range.lc?.split("#")[0];
    let std_lc = datasheetDetails.lc?.split("#")[0];

    // 2. calculate range lc precision count
    let lc_precision_count = 0;
    if (lc?.includes(".")) {
      lc_precision_count = lc.split(".")[1].length;
    }

    // 3. calculate standard lc precision count
    let std_lc_precision_count = 0;
    if (std_lc?.includes(".")) {
      std_lc_precision_count = std_lc.split(".")[1].length;
    }

    // 4. compare both precision count
    if (lc_precision_count < std_lc_precision_count) {
      dispatch({
        field: "indicators",
        value: {
          ...indicators,
          invalidLCPrecision: true,
        },
      });
    }
  };

  const updateSelectedStandardMasterIds = (selectedRangeIds, tableId) => {
    // 1. extract selected standard ids from selected range ids
    let selectedStandardIds = standardRanges.map((range) => {
      let status = false;
      selectedRangeIds
        ?.filter((item) => item)
        ?.forEach((rangeIds) => {
          rangeIds = rangeIds?.split(",").map((e) => e.split(":")[1]);
          if (rangeIds?.includes(String(range.rangeId)) === true) status = true;
        });
      if (status === true) {
        validateSelectedStandardRange(range, tableId);
        return range.id;
      } else return null;
    });

    // 2. update selected standard ids
    let _selectedStandardIds = selectedStandardIds.filter((e) => e > 0);
    _selectedStandardIds = [
      ...new Set([..._selectedStandardIds, ...selectedStandardIds]),
    ];

    dispatch({
      field: "selectedStandardIds",
      value: [..._selectedStandardIds],
    });
  };

  let parseRows = (tableRows) => {
    let base = 2;
    return Object.values(tableRows).map((rows) =>
      rows.map((row, idx) =>
        row.map((col, col_id) =>
          col_id >= base && col_id < row.length - 2 && col
            ? col.split("#")[0]
            : col
        )
      )
    );
  };

  const initiateStaticReadingRows = () => {
    if (!(datasheetStaticReadings && certificateStaticReadings)) return null;
    let rows = {};
    let _selectedRangeIds = selectedRangeIds;
    let tS = [];
    let rowUnitMap = {};
    let CurrentUnitRow = -1;
    let _certificateReadingIds = {
      ...certificateReadingIds,
    };

    let tableMap = {};
    staticTables?.map((s) => (tableMap[s.id] = s));

    datasheetStaticReadings.forEach((reading, index) => {
      let row = [];
      row.push(reading.tableId);
      row.push(reading.datasheetId);

      if (!rowUnitMap[reading.tableId]) {
        rowUnitMap[reading.tableId] = {};
      }
      if (reading["c1"].includes("_unit_")) {
        CurrentUnitRow = [reading.id, reading.uncertainty];
      } else {
        rowUnitMap[reading.tableId][reading.id] = CurrentUnitRow;
      }

      // check for readonly value and prepend $ if not present
      for (let i = 0; i < MAX_COLS; i++) {
        let value = reading["c" + (i + 1)];
        if (value != null) {
          if (value.includes("_unit_") || value.includes("_rh_")) {
            row.push(value);
          } else {
            if (value === "NA") value = "NA";
            else {
              let prefix = `${
                derivedColumns[reading.tableId]?.includes("c" + (i + 1)) ||
                tableMap[reading.tableId]?.[`c${i + 1}`]?.includes(
                  UNCERTAINTY_LINK
                )
                  ? "$"
                  : ""
              }`;
              value = value.replaceAll("$", "");
              value = prefix + value;
            }
            row.push(value);
          }
        } else {
          break;
        }
      }

      // set readonly $ for uncertainty
      if (
        reading?.uncertainty?.includes("_unit_") ||
        reading?.uncertainty?.includes("_rh_")
      ) {
        row.push(reading?.uncertainty);
      } else {
        row.push(("$" + reading?.uncertainty).replace(/\$+/g, "$"));
      }

      row.push(reading.standardRanges);
      row.push(reading.id);
      _certificateReadingIds[reading.id] = certificateStaticReadings[index]?.id;
      // IMP NOTE:
      // this is highly sensitive array, before adding any new fields to row, please check the consequences and impacting area specially static indexing
      if (rows[reading.tableId]?.length > 0) {
        rows[reading.tableId] = [...rows[reading.tableId], [...row]];
      } else {
        rows[reading.tableId] = [[...row]];
      }
      tS.push(reading.supportiveRanges);

      // set current ranges to selectedRangeIds
      _selectedRangeIds[index] = reading.standardRanges;
    });

    // set default vales if any

    rows = parseRows(rows);

    dispatch({
      field: "selectedSupportiveRanges",
      value: [...tS],
    });
    setSelectedRangeIds(_selectedRangeIds);

    let allRows = [];
    for (const tableId in rows) {
      if (rows[tableId]) allRows.push(...rows[tableId]);
    }
    setStaticReadingRows([...allRows]);
    dispatch({ field: "staticReadingRows", value: [...allRows] });
    setCertificateReadingIds({ ..._certificateReadingIds });
  };

  const addStaticReadingRow = (
    tableIndex,
    tableId,
    rowIndex = null,
    type = null
  ) => {
    let newReadingRows = [...staticReadingRows];
    let totalColumns = getCustomColumnCount(tableId, "static");

    let row = [tableId, Number(datasheetId)].concat(
      Array(totalColumns).fill("")
    );
    let tableMap = {};
    staticTables?.map((s) => (tableMap[s.id] = s));
    row = row.map((e, i) =>
      i !== 0 && i !== 1
        ? e === "NA"
          ? ""
          : `${
              derivedColumns[tableId].includes("c" + (i - 1)) ||
              tableMap[tableId][`c${i - 1}`]?.includes(UNCERTAINTY_LINK)
                ? "$"
                : ""
            }${e}`
        : e
    );
    // if (unceraintyConfig[tableId].isUncertainty)
    row.push("$"); // uncertainty
    row.push(null); // standard range
    row.push(0); // database row id

    if (
      unceraintyConfig[tableId].defaultValues &&
      Object.keys(unceraintyConfig[tableId].defaultValues).length
    ) {
      let tmp = [...row];
      let updatedRows = [];
      let col = ["", ""];
      Object.keys(unceraintyConfig[tableId].defaultValues).map((val) => {
        col[0] = val;
      });
      col[1] = (unceraintyConfig[tableId].defaultValues[col[0]] || "")
        .split(",")
        .map((c) => c.trim());
      for (let i = 0; i < col[1].length; i++) {
        tmp[1 + Number(col[0].split("c")[1])] = `$${col[1][i]}`;
        updatedRows.push(tmp);
        tmp = [...row];
      }
      row = [...updatedRows];
    } else row = [row];

    if (type === "copy") {
      // row = [...staticReadingRows[rowIndex]];
      row[0][totalColumns + 3] = staticReadingRows[rowIndex][totalColumns + 3];
      let tS = [...selectedSupportiveRanges];
      tS.push(tS[rowIndex]);
      dispatch({
        field: "selectedSupportiveRanges",
        value: [...tS],
      });
    }

    // newReadingRows.push(row);
    newReadingRows = newReadingRows.concat([...row]);
    setStaticReadingRows([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
  };

  const addStaticUnitRow = (tableIndex, tableId) => {
    let newReadingRows = [...staticReadingRows];
    let totalColumns = getCustomColumnCount(tableId, "static") + 1; // for custom + uncertainty

    let row = [tableId, datasheetId].concat(Array(totalColumns).fill("_unit_"));
    if (unceraintyConfig[tableId].isUncertainty) row.push(null); // standard range
    row.push(0);
    newReadingRows.push(row);
    setStaticReadingRows([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
  };

  const addStaticRowHeader = (tableIndex, tableId) => {
    let newReadingRows = [...staticReadingRows];
    let totalColumns = getCustomColumnCount(tableId, "static") + 1; // for custom + uncertainty

    let row = [tableId, datasheetId].concat(Array(totalColumns).fill("_rh_")); //_rh_ : row header
    if (unceraintyConfig[tableId].isUncertainty) row.push(null); // standard range
    row.push(0);
    newReadingRows.push(row);
    setStaticReadingRows([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
  };

  const deleteStaticReadingRow = (rowIndex) => {
    let newReadingRows = [...staticReadingRows];
    if (newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1])
      Promise.all([
        axios.delete(
          BASE_URL +
            `datasheetStaticReadings/${
              newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1]
            }`,
          { status: -1 }
        ),
        axios.delete(
          BASE_URL +
            `certificateStaticReadings/${
              certificateReadingIds[
                newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1]
              ]
            }`,
          { status: -1 }
        ),
      ])
        .then((res) => {
          toast.success("Row deleted successfully!");
        })
        .catch((err) => {
          toast.error("Failed to delete row, error:" + err);
        });

    setDeletedIds([
      ...deletedIds,
      newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1],
    ]);

    newReadingRows.splice(rowIndex, 1);
    setStaticReadingRows([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
  };

  const updateStaticCellValue = async (rowIndex, cellIndex, value) => {
    let newReadingRows = [...staticReadingRows];
    if (cellIndex === 0 && value.includes("_unit_")) {
      newReadingRows[rowIndex][cellIndex + 2] = value;
      let tmp = newReadingRows[rowIndex].map((e, i) =>
        i > 1 &&
        i < newReadingRows[rowIndex].length - 2 &&
        e.replaceAll("_unit_", "").length === 0
          ? value
          : e
      );
      newReadingRows[rowIndex] = tmp;
    } else newReadingRows[rowIndex][cellIndex + 2] = value;
    setStaticReadingRows([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
    updateComplianceStatus(true);
    setActiveRowIndex(-1);
    setActiveTable(-1);
  };

  const updateStandardRangesOfRow = (value, index) => {
    if (index === -1) {
      let _selectedSupportiveRanges = [...selectedSupportiveRanges];
      _selectedSupportiveRanges[activeRowIndex] = value;
      dispatch({
        field: "selectedSupportiveRanges",
        value: [..._selectedSupportiveRanges],
      });
      return;
    }

    let _staticReadingRows = [...staticReadingRows];
    // TODO: correct below logic, as it is highly coupled to index which it should not at all !!!
    let rangeIds =
      _staticReadingRows[activeRowIndex][
        _staticReadingRows[activeRowIndex].length - 2
      ];
    rangeIds = String(rangeIds || "").split(",");

    rangeIds[index - 1] = value;

    _staticReadingRows[activeRowIndex][
      _staticReadingRows[activeRowIndex].length - 2
    ] = rangeIds.join(",");

    setStaticReadingRows([..._staticReadingRows]);
    dispatch({ field: "staticReadingRows", value: [..._staticReadingRows] });

    let _selectedRangeIds = selectedRangeIds;
    _selectedRangeIds[activeRowIndex] = rangeIds.join(",");
    setSelectedRangeIds(_selectedRangeIds);

    let tableId = _staticReadingRows[activeRowIndex][0];
    updateSelectedStandardMasterIds(_selectedRangeIds, tableId);
  };

  const updateComplianceStatus = (complianceStatus) => {
    if (indicators?.complianceStatus !== complianceStatus) {
      dispatch({
        field: "indicators",
        value: { ...indicators, complianceStatus: complianceStatus },
      });
    }
  };

  const deleteTable = (tableId) => {
    // append into previoud ids
    let data = {
      deletedTableIds:
        (datasheetDetails?.deletedTableIds
          ? datasheetDetails?.deletedTableIds + ","
          : "") + tableId,
    };
    Promise.all([
      axios
        .patch(BASE_URL + `datasheets/${datasheetId}`, data)
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        }),
      axios
        .patch(BASE_URL + `certificates/${datasheetId}`, data)
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        }),
    ])
      .then((res) => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.error(`[ERROR] Failed to delete table, error: ${err}`);
      });
  };

  useEffect(() => {
    if (standardRanges) fetchStaticReadingRows(datasheetId, dispatch);
  }, [standardRanges]);

  useEffect(() => {
    if (
      datasheetStaticReadings?.length > 0 &&
      certificateStaticReadings?.length > 0
    ) {
      initiateStaticReadingRows();
    }
  }, [datasheetStaticReadings, certificateStaticReadings]);

  useEffect(() => {
    if (datasheetDetails?.instrumentId < 1) return;

    fetchDatasheetStaticTables(
      datasheetDetails.instrumentId,
      datasheetDetails.deletedTableIds, //TODO: pass static tables deleted ids only
      setDerivedColumns,
      setUnceraintyConfig,
      dispatch
    );
    fetchCertificateStaticTables(
      datasheetDetails.instrumentId,
      datasheetDetails.deletedTableIds, // considered as certificated table id as both have same table ids
      dispatch
    );
  }, [datasheetDetails.instrumentId, datasheetDetails.deletedTableIds]);

  // render block
  const renderCommonHeader = (defaultConfiguration) => {
    if (!defaultConfiguration) return null;
    defaultConfiguration = JSON.parse(defaultConfiguration);
    let hasCommonHeader = defaultConfiguration.hasCommonHeader;
    if (hasCommonHeader && defaultConfiguration.commonHeaderConfig) {
      let commonHeaderConfig =
        defaultConfiguration.commonHeaderConfig?.split(",");
      let cols = [];
      commonHeaderConfig.forEach((col, index) => {
        let [name, size] = col?.split(":");
        let classNames =
          "header-border " + (name != "$" ? "header-border-bottom" : "");
        cols.push(
          <TableCell
            key={"common-header-" + index}
            className={classNames}
            align="center"
            style={{ textAlign: "center" }}
            colSpan={Number(size)}
          >
            {name != "$" ? name : ""}
          </TableCell>
        );
      });

      return (
        <TableRow>
          {/* for SN */}
          {!isPrinting && (
            <>
              <TableCell className="header-border" align="center"></TableCell>
              <TableCell className="header-border" align="center"></TableCell>
            </>
          )}
          {cols}
        </TableRow>
      );
    }
    return null;
  };

  const renderUnit = (value, onChange) => {
    return (
      <div id="datasheet_editdatasheet_readingrow_selectunit">
        {isPrinting ? (
          <Typography variant="p">{value}</Typography>
        ) : (
          <select name="units" id="units" onChange={onChange} value={value}>
            <option value="">Select Unit</option>
            {unitSymbols?.map((unit) => {
              return (
                <option value={unit} key={unit}>
                  {unit}
                </option>
              );
            })}
          </select>
        )}
      </div>
    );
  };

  const getStandardRangeLC = (
    standardRangeId,
    stdRangePref,
    isRange,
    tableIndex
  ) => {
    standardRangeId = standardRangeId?.split(",").map((e) => e.split(":")[1])[
      stdRangePref > 0 ? stdRangePref - 1 : 0
    ];

    let range = standardRanges?.filter(
      (range) => range.rangeId === Number(standardRangeId)
    );
    let duc_ranges;
    if (isRange) {
      duc_ranges = datasheetDetails?.ranges?.split("||");
      if (duc_ranges?.length > 1) {
        duc_ranges = duc_ranges[tableIndex || 0];
      } else duc_ranges = duc_ranges[0];
      range = range?.[0]?.range
        ? Math.max(
            ...(range[0]?.range
              .split("|")
              .map((e) => Number(e.split("#")[0]))
              .filter((e) => !isNaN(e)) || [])
          )
        : null;
      duc_ranges =
        duc_ranges !== undefined || duc_ranges !== null
          ? Math.max(
              ...(duc_ranges
                .split("|")
                .map((e) => Number(e.split("#")[0]))
                .filter((e) => !isNaN(e)) || [])
            )
          : null;

      if (range && range !== null && duc_ranges && duc_ranges !== null)
        range = Math.min(range, duc_ranges);
      else if (!range && duc_ranges) range = duc_ranges;
    }

    return isRange ? range : range?.[0]?.lc.split("#")[0];
  };

  const getConditionalFormattedColor = (
    value,
    cellIndex,
    table,
    tableIndex,
    rowIndex
  ) => {
    value = `${value}`.replaceAll("$", "");
    let { relations } = JSON.parse(table?.conditionFormatting || "{}");
    let dsCol;
    let config = relations["c" + cellIndex];
    if (relations) {
      if (config) {
        let _dsCol = config.dsCol.split(".");
        dsCol = !String(_dsCol[0])?.includes("ds")
          ? Number(_dsCol[0])
          : _dsCol[0];
        let stdRangePref = Number(_dsCol[1] || "0");

        let valToCompare = 0;
        if (String(dsCol)?.includes("ds")) {
          _dsCol = dsCol.split("ds")[1];
          valToCompare = staticReadingRows[rowIndex][Number(_dsCol) + 2 - 1];
        } else {
          let possibleValues = [
            0,
            getStandardRangeLC(
              staticReadingRows[rowIndex][
                staticReadingRows[rowIndex].length - 2
              ],
              stdRangePref,
              true,
              tableIndex
            ) || 0,
            datasheetDetails.lc,
            getStandardRangeLC(
              staticReadingRows[rowIndex][
                staticReadingRows[rowIndex].length - 2
              ],
              stdRangePref
            ) || 0,
            datasheetDetails.accuracy,
          ];
          valToCompare = possibleValues[dsCol - 1];
        }

        valToCompare = String(valToCompare)
          ?.replace("\n", "")
          .replaceAll("$", "")
          .split("||");

        valToCompare =
          valToCompare?.length > tableIndex
            ? valToCompare[tableIndex]
            : valToCompare[0];

        // remove unit if it contains
        valToCompare = valToCompare?.split("#")[0];

        if (
          config.condition?.toLowerCase() === "lt" &&
          Number(dsCol == 2 ? value : Math.abs(value)) <
            Number(dsCol == 2 ? valToCompare : Math.abs(valToCompare))
        ) {
          console.log("lt");
          updateComplianceStatus(false);
          return config.color;
        } else if (
          config.condition?.toLowerCase() === "gt" &&
          Number(dsCol == 2 ? value : Math.abs(value)) >
            Number(dsCol == 2 ? valToCompare : Math.abs(valToCompare))
        ) {
          console.log("gt");
          updateComplianceStatus(false);

          // alert("Calibration Point Can't be greater than Master Range!");
          if (
            dsCol === 2 &&
            !indicators.outOffRangeTracker[
              `${tableIndex}_${rowIndex}_${cellIndex}`
            ]
          ) {
            dispatch({
              field: "indicators",
              value: {
                ...indicators,
                outOffRangeTracker: {
                  ...indicators.outOffRangeTracker,
                  [`${tableIndex}_${rowIndex}_${cellIndex}`]: true,
                },
              },
            });
          }
          return config.color;
        } else if (
          config.condition?.toLowerCase() === "precisioncountnotequal"
        ) {
          value = value.toString();
          let valueDecimalCount = value.includes(".")
            ? value.split(".").pop().length
            : 0;
          valToCompare = valToCompare.toString();
          let valToCompareDecimalCount = 0;
          if (String(valToCompare).includes(".")) {
            valToCompareDecimalCount = String(valToCompare).split(".")[1];
            valToCompareDecimalCount =
              valToCompareDecimalCount.split("#")[0].length;
          }

          if (valueDecimalCount !== valToCompareDecimalCount) {
            updateComplianceStatus(false);
            if (!precNotEqTracker[`${tableIndex}_${rowIndex}_${cellIndex}`]) {
              setPrecNotEqTracker({
                ...precNotEqTracker,
                [`${tableIndex}_${rowIndex}_${cellIndex}`]: true,
              });
            }
            return config.color;
          }
        }
      }
    }
    if (
      dsCol == 2 &&
      indicators.outOffRangeTracker[`${tableIndex}_${rowIndex}_${cellIndex}`]
    ) {
      let tmp = { ...indicators.outOffRangeTracker };
      delete tmp[`${tableIndex}_${rowIndex}_${cellIndex}`];
      dispatch({
        field: "indicators",
        value: {
          ...indicators,
          outOffRangeTracker: tmp,
        },
      });
    }
    if (
      config?.condition?.toLowerCase() === "precisioncountnotequal" &&
      precNotEqTracker[`${tableIndex}_${rowIndex}_${cellIndex}`]
    ) {
      let tmp = { ...precNotEqTracker };
      delete tmp[`${tableIndex}_${rowIndex}_${cellIndex}`];
      setPrecNotEqTracker(tmp);
    }
    return "";
  };

  const renderTableCell = (
    table,
    tableIndex,
    row,
    rowIndex,
    cell,
    cellIndex,
    onChange = {}
  ) => {
    let field = null;

    // 1. it its unit field
    if (String(cell)?.includes("_unit_")) {
      //removed check to check to show text field instead of unit for m-x
      let value = cell.split("_unit_")[1];
      field = renderUnit(value, (e) => {
        updateStaticCellValue(rowIndex, cellIndex, "_unit_" + e.target.value);
      });
    }
    // 2. if its read only value field
    else if (cell && cell[0] == "$") {
      field = (
        <span
          style={{
            color: getConditionalFormattedColor(
              row[cellIndex + 2],
              cellIndex + 1,
              table,
              tableIndex,
              rowIndex
            ),
          }}
        >
          {cell.slice(1)}
        </span>
      );
    } else if (cell == "null" && cell == null) {
      field = (
        <TextField
          size="small"
          value={""}
          InputProps={{
            style: {
              fontSize: "12px",
              margin: "0px",
              padding: "0px",
            },
          }}
          onChange={(e) => {
            updateStaticCellValue(rowIndex, cellIndex, e.target.value);
          }}
          variant="standard"
        ></TextField>
      );
    }
    // 3. if its a value input field
    else {
      field = (
        <>
          {isPrinting ? (
            <Typography variant="p">{row[cellIndex + 2]}</Typography>
          ) : (
            <TextField
              id="datasheet_editdatasheet_readingrow_enterReading"
              size="small"
              sx={{
                input: {
                  color: getConditionalFormattedColor(
                    row[cellIndex + 2],
                    cellIndex + 1,
                    table,
                    tableIndex,
                    rowIndex
                  ),
                },
              }}
              defaultValue={row[cellIndex + 2]}
              InputProps={{
                style: {
                  fontSize: "12px",
                  margin: "0px",
                  padding: "0px",
                },
              }}
              onBlur={async (e) => {
                updateStaticCellValue(rowIndex, cellIndex, e.target.value);
              }}
              variant="standard"
            ></TextField>
          )}
        </>
      );
    }
    return (
      <TableCell
        key={cellIndex}
        style={{
          padding: "4px",
        }}
        variant="standard"
      >
        {field}
      </TableCell>
    );
  };

  const renderActions = (
    row,
    rowIndex,
    range,
    tableId,
    tableIndex,
    hasActions = false
  ) => {
    return (
      <ButtonGroup size="small" aria-label="small button group">
        {hasActions && (
          <>
            <Button
              id="datasheet_editdatasheet_readingrow_copy"
              onClick={(e) => {
                addStaticReadingRow(tableIndex, tableId, rowIndex, "copy");
              }}
            >
              <ContentCopyIcon />
            </Button>
          </>
        )}
      </ButtonGroup>
    );
  };

  const renderRows = (table, tableIndex, staticReadingRows, range) => {
    let rows = [];
    let rowType = "";
    staticReadingRows?.forEach((row, rowIndex) => {
      let _row = [];
      if (row[0] == table.id) {
        if (String(row[2])?.includes("_rh_")) {
          rowType = "header";
          _row = (
            <TableRow key={"reading-row" + rowIndex}>
              {!isPrinting ? (
                <TableCell>
                  {renderActions(row, rowIndex, range, table.id, tableIndex)}
                </TableCell>
              ) : null}
              <TableCell colSpan={row.length - 3 - (isPrinting ? 2 : 0)}>
                <TextField
                  id="datasheet_editdatasheet_readingrow_enterrrowheading"
                  size="small"
                  fullWidth
                  value={row[0 + 2].split("_rh_")[1]} // 2: row_base
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      margin: "auto",
                      padding: "0px",
                    },
                  }}
                  onChange={(e) => {
                    updateStaticCellValue(rowIndex, 0, "_rh_" + e.target.value);
                  }}
                ></TextField>
              </TableCell>
              {!isPrinting ? (
                <TableCell>
                  {showComponent("delete") && (
                    <DeleteIcon
                      id="datasheet_editdatasheet_readingrowheader_delete"
                      style={{
                        color: "#dc3545",
                      }}
                      onClick={(e) => {
                        // TODO: correct below logic
                        deleteStaticReadingRow(rowIndex);
                        let tmp = { ...indicators.outOffRangeTracker };
                        let tmp2 = { ...indicators.outOffRangeTracker };
                        let tmp3 = { ...precNotEqTracker };
                        let tmp4 = { ...precNotEqTracker };
                        Object.keys(tmp).map((key) => {
                          if (key.includes(`${tableIndex}_${rowIndex}_`)) {
                            delete tmp2[key];
                          }
                        });
                        Object.keys(tmp3).map((key) => {
                          if (key.includes(`${tableIndex}_${rowIndex}_`)) {
                            delete tmp4[key];
                          }
                        });
                        dispatch({
                          field: "indicators",
                          value: {
                            ...indicators,
                            outOffRangeTracker: tmp2,
                          },
                        });
                        setPrecNotEqTracker(tmp4);
                      }}
                    />
                  )}
                </TableCell>
              ) : null}
            </TableRow>
          );
        } else if (String(row[2])?.includes("_unit_")) {
          rowType = "unit";
          _row = (
            <TableRow key={rowIndex}>
              {!isPrinting ? (
                <TableCell>
                  {renderActions(row, rowIndex, range, table.id, tableIndex)}
                </TableCell>
              ) : (
                ""
              )}
              {!isPrinting && <TableCell></TableCell>}
              {row
                .slice(
                  2,
                  row.length -
                    (!isPrinting && unceraintyConfig[table.id]?.isUncertainty
                      ? 2
                      : 3)
                ) // 2: tableId + datasheetId, 1: uncertainty, stdRanges
                .map((cell, cellIndex) =>
                  renderTableCell(
                    table,
                    tableIndex,
                    row,
                    rowIndex,
                    cell,
                    cellIndex
                  )
                )}
              {!isPrinting && (
                <TableCell>
                  {showComponent("delete") && (
                    <DeleteIcon
                      id="datasheet_editdatasheet_readingrowunit_delete"
                      style={{
                        color: "#dc3545",
                      }}
                      onClick={(e) => {
                        deleteStaticReadingRow(rowIndex);
                      }}
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
          );
        } else {
          rowType = "data";
          _row = (
            <TableRow key={rowIndex}>
              {!isPrinting ? (
                <TableCell>
                  {renderActions(
                    row,
                    rowIndex,
                    range,
                    table.id,
                    tableIndex,
                    true
                  )}
                </TableCell>
              ) : (
                ""
              )}
              {!isPrinting ? (
                <TableCell>
                  <button
                    id="datasheet_editdatasheet_readingrow_selectmaster"
                    style={{
                      color: checkMaster(rowIndex) ? "green" : "red",
                      rotate:
                        table.id === activeTable && rowIndex === activeRowIndex
                          ? "180deg"
                          : "0deg",
                    }}
                    onClick={() => {
                      if (activeTable === -1 || activeRowIndex === -1) {
                        setActiveRowIndex(rowIndex);
                        setActiveTable(table.id);
                      } else if (
                        activeTable === table.id &&
                        activeRowIndex === rowIndex
                      ) {
                        setActiveRowIndex(-1);
                        setActiveTable(-1);
                      } else {
                        setActiveRowIndex(rowIndex);
                        setActiveTable(table.id);
                      }
                    }}
                  >
                    <ExpandCircleDownOutlined />
                  </button>
                </TableCell>
              ) : (
                ""
              )}
              {row
                .slice(
                  2,
                  row.length -
                    (!isPrinting && unceraintyConfig[table.id]?.isUncertainty
                      ? 2
                      : 3)
                ) // 2: tableId + datasheetId, 2: standardRanges, rowId
                .map((cell, cellIndex) =>
                  renderTableCell(
                    table,
                    tableIndex,
                    row,
                    rowIndex,
                    cell,
                    cellIndex
                  )
                )}

              {!isPrinting ? (
                <TableCell>
                  {showComponent("delete") && (
                    <DeleteIcon
                      id="datasheet_editdatasheet_readingrow_delete"
                      style={{
                        color: "#dc3545",
                      }}
                      onClick={(e) => {
                        deleteStaticReadingRow(rowIndex);
                      }}
                    />
                  )}
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          );
        }
      }
      if (
        activeTable === table.id &&
        rowIndex == activeRowIndex &&
        rowType == "data"
      ) {
        let totalStandards = standardMasterArray?.length || 0;
        let standardRow = (
          <tr id="datasheet_editdatasheet_readingrow_selectmasterrow">
            <td></td>
            <td></td>
            <TableCell colSpan={row.length - 2}>
              {" "}
              <StandardMasterSelection
                index={0}
                label="Standard master 1"
                standardRanges={standardRanges}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[0] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={1}
                label="Standard master 2"
                standardRanges={standardRanges}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[1] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={2}
                label="Standard master 3"
                standardRanges={standardRanges}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[2] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={3}
                label="Standard master 4"
                standardRanges={standardRanges}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[3] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={0}
                label="Supportive master"
                standardRanges={supportiveStandardMasterArray}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                isSupportive={true}
                valId={selectedSupportiveRanges[activeRowIndex] || ""}
              />
            </TableCell>
          </tr>
        );
        rows.push(_row, standardRow);
      } else {
        rows.push(_row);
      }
    });

    return rows;
  };

  const renderStaticTables = () => {
    if (!staticTables) return <Box sx={{ width: "100%" }}>Loading...</Box>;
    if (staticTables.length < 1)
      return <Box sx={{ width: "100%" }}>No tables found!</Box>;

    let range = 0;
    return staticTables?.map((table, tableIndex) => {
      if (datasheetDetails.tableDisplayStatus === 1 && table.isAutomated !== 0)
        return null;
      else if (
        datasheetDetails.tableDisplayStatus === 2 &&
        table.isAutomated !== 1
      )
        return null;

      range = 0;
      return (
        <div key={tableIndex} sx={{ mb: 5 }} style={{ marginBottom: "60px" }}>
          {!isPrinting ? (
            <>
              <Typography
                variant="h6"
                component="div"
                style={{ float: "left" }}
              >
                {tableIndex + 1 + ". "}
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="standard"
                  type="text"
                  value={
                    datasheetDetails?.configuration?.customTableNames?.[
                      table.id
                    ] || table.tableName
                  }
                  onChange={(e) => {
                    dispatch({
                      field: "datasheetDetials",
                      value: {
                        ...datasheetDetails,
                        configuration: {
                          ...datasheetDetails.configuration,
                          customTableNames: {
                            ...datasheetDetails.configuration.customTableNames,
                            [table.id]: e.target.value,
                          },
                        },
                      },
                    });
                  }}
                />
                {showComponent("delete") ? "|" : ""}
                {showComponent("delete") && (
                  <Button size="sm">
                    <DeleteIcon
                      id="datasheet_editdatasheet_readingtablehead_delete"
                      style={{
                        color: "#8d7a7c",
                      }}
                      onClick={(e) => {
                        deleteTable(table.id);
                      }}
                    />
                  </Button>
                )}
              </Typography>
            </>
          ) : (
            ""
          )}
          <div style={{ width: "100%", overflow: "auto" }}>
            <ClassicTable>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  {renderCommonHeader(table?.defaultConfiguration)}
                  <TableRow>
                    {!isPrinting ? (
                      <>
                        <TableCell>
                          <Button
                            id="datasheet_editdatasheet_readingtablehead_expanded_uncertainty"
                            variant="outlined"
                            size="small"
                            style={{
                              fontSize: "12px",
                              width: "100px",
                            }}
                            component={Link}
                            to={`/viewAll2/${datasheetId}/${datasheetDetails.instrumentId}/1/${table.id}`}
                          >
                            Expanded Uncertainty
                          </Button>
                        </TableCell>
                        <TableCell
                          className="header-border"
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          <Typography noWrap style={{ fontSize: "12px" }}>
                            Select Master
                          </Typography>
                        </TableCell>
                      </>
                    ) : (
                      ""
                    )}
                    {/* render columns*/}
                    {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                      if (table["c" + i]?.toLowerCase()?.match(/m+\d+/g)) {
                        range++;
                      }
                      if (table["c" + i] != null) {
                        return (
                          <TableCell
                            key={"static-table-column" + i}
                            className="header-border"
                            align="center"
                            style={{ textAlign: "center" }}
                          >
                            <div>
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {table["c" + i]?.includes(UNCERTAINTY_LINK)
                                  ? table["c" + i]?.split(UNCERTAINTY_LINK)?.[0]
                                  : table["c" + i]?.includes(EXTRA_COLUMN_LINK)
                                  ? table["c" + i]?.split(
                                      EXTRA_COLUMN_LINK
                                    )?.[0]
                                  : table["c" + i]}
                              </Typography>
                            </div>
                          </TableCell>
                        );
                      }
                    })}
                    {!isPrinting &&
                    unceraintyConfig[table.id]?.isUncertainty ? (
                      <TableCell>
                        <Typography
                          noWrap
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Uncertainty
                        </Typography>
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {!isPrinting ? (
                      <TableCell
                        className="header-border"
                        align="center"
                        style={{ textAlign: "center" }}
                      ></TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows(table, tableIndex, staticReadingRows, range)}
                </TableBody>
              </Table>
            </ClassicTable>
          </div>
          {!isPrinting ? (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  margin: "10px 0px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  id="datasheet_editdatasheet_readingadd"
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    addStaticReadingRow(tableIndex, table.id);
                  }}
                >
                  <AddIcon /> Reading(s)
                </Button>
                <Button
                  id="datasheet_editdatasheet_readingunit"
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    addStaticUnitRow(tableIndex, table.id);
                  }}
                >
                  <AddIcon /> Unit
                </Button>
                <Button
                  id="datasheet_editdatasheet_readingheader"
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    addStaticRowHeader(tableIndex, table.id);
                  }}
                >
                  <AddIcon /> Row header
                </Button>
              </div>
              <Divider
                sx={{
                  backgroundColor: "black",
                  height: "2px",
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>
      );
    });
  };

  const renderComplianceStatus = () => {
    let color = "black";
    let complianceStatus = "N/A";
    if (staticReadingRows?.length > 0) {
      if (indicators?.complianceStatus == true) {
        color = "green";
        complianceStatus = "Pass";
      } else if (indicators?.complianceStatus == false) {
        color = "red";
        complianceStatus = "Fail";
      }
    }
    return (
      <div>
        <h5 style={{ color: color }}>
          Compliance Status:
          {complianceStatus}
        </h5>
      </div>
    );
  };

  return (
    <div>
      {!isPrinting && (
        <>
        <div>
          <Typography variant="h6" component="div">
            Observed Readings
          </Typography>
          <Button
            size="sm"
            onClick={(e) => {
              fetchOtherStaticReadingRows(
                datasheetId,
                datasheetStaticReadings,
                datasheetDetails,
                dispatch
              );
            }}
          >
            Autoload
          </Button>
        </div>
        <hr style={{ border: "1px solid black" }} />
        </>
      )
      }

      <div className="ds-tables">{renderStaticTables()}</div>

      {!isPrinting && (
        <>
          <hr style={{ border: "1px solid black" }} />
          {renderComplianceStatus()}
        </>
      )}
    </div>
  );
}
