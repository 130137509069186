//viewQuotation
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import { ToWords } from "to-words";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Autocomplete,
  TableContainer,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import "./viewQuotation.css";
import UnitechHeader from "../image/header.png";
import UnitechFooter from "../image/footer.png";
import { viewQuotationGuide } from "./guide";
import { fetchAddresses } from "../invoice/productDetails";
const axios = require("axios");
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
    },
  },
  row: {
    height: 5,
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  cell50: {
    padding: 1,
    width: "50%",
    height: 5,
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 50,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 50,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 30,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 50,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
  spacialCaseBorder: {
    borderBottom: "1px solid black !important",
  },
});

function InstrumentTable({ classes, quotationProductDetails, productList1 ,quotationAllData}) {
  const toWords = new ToWords();
  let productDeatails = quotationProductDetails;
  let productList = productList1;
  const hasDiscount = Array.isArray(productDeatails) && productDeatails?.some(product => product?.discount !== null);

  var amounts = {
    totalAmountBeforeTax: 0,
    gst: 0,
    sgst: 0,
    cgst: 0,
    conveyanceCharges: 0,
    courierCharges: 0,
    grandTotal: 0,
    discount:0
  };

  if (productDeatails.length > 0 && productList.length > 0) {
    for (let i = 0; i < productDeatails.length; i++) {
      const result = productList.find(
        ({ id }) => id === productDeatails[i].equipmentId
      );
      productDeatails[i].name = result.instrumentName;
      let totalAmount = productDeatails[i].rate * productDeatails[i].quantity;
      let discountAmount = (totalAmount * (parseFloat(productDeatails[i]?.discount) || 0)) / 100;
      productDeatails[i].amount = totalAmount - discountAmount;
      amounts.totalAmountBeforeTax += productDeatails[i].amount;
    }
    amounts.conveyanceCharges = parseInt(
      quotationAllData[0]?.conveyanceCharges
        ? quotationAllData[0]?.conveyanceCharges
        : "0"
    );
    amounts.courierCharges = parseInt(
      quotationAllData[0]?.courierCharges
        ? quotationAllData[0]?.courierCharges
        : "0"
    );
    amounts.totalAmountBeforeTax +=
      amounts.conveyanceCharges + amounts.courierCharges;
      
      amounts.discount = quotationAllData[0]?.discount ;
      let discountcal = (amounts.totalAmountBeforeTax * amounts.discount) / 100;     
      
      amounts.totalAmountBeforeTax = amounts.totalAmountBeforeTax - discountcal;
      
      const gstType = quotationAllData[0]?.gstType;
      const gstPercentages = quotationAllData[0]?.gstpercentages;
      
      if (gstType === 1) {
        const percentage = gstPercentages ? gstPercentages / 2 : 9;
        amounts.sgst = amounts.cgst = (amounts.totalAmountBeforeTax * percentage) / 100;
        amounts.gst = amounts.sgst + amounts.cgst;
      } else if (gstType === 2) {
        amounts.gst = (amounts.totalAmountBeforeTax * (gstPercentages || 18)) / 100;
      } else {
        amounts.gst = 0;
      }    
    amounts.grandTotal = amounts.gst + amounts.totalAmountBeforeTax;
  }

  return (
    <Table mt={5} className={["single-border-table", classes.table]}>
      <TableRow style={{ backgroundColor: "#D9D9D9" }} id="pagbreaksborder">
        <TableCell className={classes.srCell} align="center">
          <b>Sr. No</b>
        </TableCell>
        <TableCell className={classes.nameCell} align="center">
          <b>Equipment Name</b>
        </TableCell>
        <TableCell className={classes.nameCell} align="center">
          <b>Description</b>
        </TableCell>
        <TableCell className={classes.makeCell} align="center">
          <b>SAC | HSN Code Job Type</b>
        </TableCell>
        <TableCell className={classes.rangeCell} align="center">
          <b>Type of Service</b>
        </TableCell>
        <TableCell className={classes.rangeCell} align="center">
          <b>Rate</b>
        </TableCell>
        <TableCell className={classes.qntCell} align="center">
          <b>Quantity (No)</b>
        </TableCell>
        {hasDiscount && (
        <TableCell className={classes.qntCell} align="center">
          <b>Discount (%)</b>
        </TableCell>        
          )}
        <TableCell className={classes.rateCell} align="center">
          <b>Amount</b>
        </TableCell>
      </TableRow>
      {productList &&
        productDeatails?.map((product, i) => (
          <TableRow className={classes.row} key={i} id="pagbreaksborder">
            <TableCell className={classes.srCell} align="center">
              {i + 1}
            </TableCell>
            <TableCell className={classes.nameCell} align="center">
              {product?.name}
            </TableCell>
            <TableCell className={classes.nameCell} align="center">
              {product?.description}
            </TableCell>
            <TableCell className={classes.makeCell} align="center">
              {product?.hsnCode}
            </TableCell>
            <TableCell className={classes.rangeCell} align="center">
              {product?.service}
            </TableCell>
            <TableCell className={classes.qntCell} align="center">
              {product?.rate?.toFixed(2)}
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              {product?.quantity}
            </TableCell>
            {hasDiscount ? (
              product?.discount !== null ? (
            <TableCell className={classes.rateCell} align="center">
              {product?.discount}
            </TableCell>   
               ) : (
                <TableCell className={classes.rateCell} align="center">
                </TableCell>
              )
            ) : null}          
            <TableCell className={classes.rateCell} align="center">
              {product?.amount?.toFixed(2)}
            </TableCell>
          </TableRow>
        ))}
      {amounts.conveyanceCharges !== 0 && (
      <TableRow id="pagbreaksborder">
          <TableCell className={classes.nameCell} colSpan={hasDiscount ? 8 : 7} align="right">
            <b>Onsites Charges &nbsp;</b>
          </TableCell>
          <TableCell className={classes.rateCell} align="center">
            <b>
              {" "}
              {amounts.conveyanceCharges
                ? amounts.conveyanceCharges?.toFixed(2)
                : 0}
            </b>
          </TableCell>
        </TableRow>
        )}
       {amounts.courierCharges !== 0 && (
      <TableRow id="pagbreaksborder">
        <TableCell className={classes.nameCell} colSpan={hasDiscount ? 8 : 7} align="right">
          <b> Courier Charges &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b>
            {amounts.courierCharges ? amounts.courierCharges?.toFixed(2) : 0}
          </b>
        </TableCell>
      </TableRow>
         )}
      {amounts?.discount ?
      <TableRow id="pagbreaksborder">
        <TableCell className={classes.nameCell} colSpan={hasDiscount ? 8 : 7} align="right">
          <b>Discount&nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
        <b>{amounts?.discount ? `${amounts?.discount} %` : ""}</b>
        </TableCell>
      </TableRow>
      : ""
      }
      <TableRow id="pagbreaksborder">
        <TableCell className={classes.nameCell} colSpan={hasDiscount ? 8 : 7} align="right">
          <b>Total Amount &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b>{amounts.totalAmountBeforeTax?.toFixed(2)}</b>
        </TableCell>
      </TableRow>
      <TableRow id="pagbreaksborder">
        <TableCell className={classes.nameCell} colSpan={hasDiscount ? 8 : 7} align="right">
          {productDeatails[0]?.qt_gstType === 1 ? (
            <b>Total GST &nbsp;</b>
          ) : (
            <b>Total GST(CGST and SGST) &nbsp;</b>
          )}
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b>{amounts.gst?.toFixed(2)}</b>
        </TableCell>
      </TableRow>     
      <TableRow style={{ backgroundColor: "#D9D9D9" }} id="pagbreaksborder">
        <TableCell className={classes.nameCell} colSpan={hasDiscount ? 8 : 7} align="right">
          <b>Grand Total Amount &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b>{amounts.grandTotal ? amounts.grandTotal?.toFixed(2) : ""}</b>
        </TableCell>
      </TableRow>
      <TableRow id="pagbreaksborder">
        <TableCell className={classes.nameCell} colSpan={hasDiscount ? 9 : 8} align="left">
          <div>
            <b style={{ padding: "1px 5px" }}>&nbsp; Amount in Words:</b>
            <b>
              &nbsp;
              {toWords.convert(
                amounts.grandTotal ? amounts.grandTotal?.toFixed(2) : 0,
                { currency: true }
              )}
            </b>
          </div>
        </TableCell>
      </TableRow>
    </Table>
  );
}

export default function EditQuotation() {
  const viewQuotationGuides = viewQuotationGuide();
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const params = useParams();
  const [productList, setProductList] = React.useState([]);
  const [details, setDetails] = React.useState({
    companyName: "",
    companyNumber: "",
    clientName: "",
    address: "",
    referenceNumber: "",
    quotationNumber: "",
    subject: "",
    Date: "",
    thankYouForEnquiry: "",
    termsConditions: "",
    clientId: "",
    conveyanceCharges: "",
    gstNumber: "",
    email: "",
  });
  const [quotationProductDetails, setProductDetails] = React.useState([]);
  const [quotationAllData, setquotatioAllData] = React.useState([]);
  const [settings, setSettings] = React.useState({});
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [contact, setContact] = React.useState({
    contname: "",
    email: "",
    contactNumber: "",
  });
  const [newAddressArray, setNewAddressArray]=React.useState([])

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:left">
         <b style="font-size:19px;">
         Thanking You <br>
         Yours faithfully,<br>
         for <b>Precise Calibration Lab, Pune.<b>  
         </b>
          </td>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
          <b style="font-size:20px;">Page Number : </b>
          <b style="font-size:19px;">${pageRange}</b>
        </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [97, 1, 60, 1],
        filename: `certificate_${params.id}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      // let image = await generateDynamicHeader(
      //   ulrNumber,
      //   certificateNumber,
      //   i,
      //   totalPages
      // );
      // pdf.addImage(image, 0, 70, 595, 55);

      let image = await generateDynamicFooter(i, totalPages);
      pdf.addImage(image, 0, 780, 595, 70);

      if (letterHead) {
        let image = await generateLetterHeadHeader();
        pdf.addImage(image, 0, 7, 595, 77);

        // image = await generateLetterHeadFooter();
        // pdf.addImage(image, 0, 785, 595, 55);
      }
    }

    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${params.quotationNumber}.pdf`;
    a.click();
    // pdf.save();
  };

  function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let _settingList = {};
      res.data.forEach((setting) => {
        _settingList[setting.keyName] = setting.value;
      });
      setSettings(_settingList);
    });
  }
  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from customProducts as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    axios
      .post(url + "dynamic", payload)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getQuat = () => {
    let url = BASE_URL;
    let paylaod = {
      query: `SELECT qt.*,qt.address as qt_address,qt.id as qt_id, client.* FROM quotation AS qt JOIN clients AS client ON qt.clientId = client.id WHERE qt.clientId = ${params.clientId} AND qt.quotationNumber LIKE '${params.quotationNumber}'`,
    };
    axios
      .post(url + "dynamic", paylaod)
      .then(async(res) => {
        let sqlQuery = {
          query: `SELECT * FROM productDetails WHERE type = 1 AND referenceId = ${res.data[0]?.qt_id}`,
        };
        const response = await axios.post(BASE_URL + `dynamic`, sqlQuery);
        setProductDetails(response.data);
        let contact = res.data[0].contact ?  JSON.parse(res.data[0].contact) : null; 
        setquotatioAllData(res.data);
        setContact({
          contname: contact[0]?.contactPersonName || "",
          email: contact[0]?.emailId || "",
          contactNumber: contact[0]?.contact || "" ,
        });

      })
      .catch((error) => {
        console.log("getquat", error);
      });
  };
  useEffect(() => {
    // getQuotations();
    fetchSettings();
    getProductList();
    getQuat();
  }, []);

useEffect(async()=>{
  let splitAddress=quotationAllData[0]?.qt_address?.split(",")
  let newAddressArray = await fetchAddresses(splitAddress?.[0],splitAddress?.[1])
  setNewAddressArray(newAddressArray)
},[quotationAllData[0]?.qt_address])

  return (
    <>
      <Paper sx={{ mx: 5, p: 2 }}>
      <div style={{display:"flex",justifyContent:"flex-end"}}>            
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          viewQuotationGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div>  
        <Grid container spacing={2} align="left" style={{ display: "flex" }}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="quotation_view_letterhead"
              options={[
                { id: 1, label: "With Letter Head" },
                { id: 2, label: "Without Letter Head" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="letterHead" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Letter Head" ||
                  newInputValue === ""
                ) {
                  setLetterHead(true);
                } else if (newInputValue === "Without Letter Head") {
                  setLetterHead(false);
                }
                // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="quotation_view__electronicsign"
              options={[
                { id: 1, label: "With Electronically Signed" },
                { id: 2, label: "Without  Electronically Signed" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Electronically Signed" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Electronically Signed" ||
                  newInputValue === ""
                ) {
                  setElectronicSign(true);
                } else if (newInputValue === "Without  Electronically Signed") {
                  setElectronicSign(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
            id="quotation_view_Download"
              variant="contained"
              size="small"
              sx={{ ml: 3 }}
              onClick={() => {
                generatePDF();
              }}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
        <br/>
        <div className="invoice" ref={printComponentRef} id="quotation_view__print">
          <div style={{ width: "100%" }} id="header-section"></div>

          {quotationAllData ? (
            <div
              style={{
                width: "95%",
                border: "1px solid black",
                textAlign: "center",
                margin: "0px auto",
                padding: "10px 10px 5px",
              }}
            >
              <Grid container spacing={2}>
                <Grid md={12} className="font-link" align="center" justifyContent={'center'}>
                  <h5 style={{ color: "#000066", margin: "5px 0px" }}>
                    <b>QUOTATION FOR CALIBRATION & TESTING SERVICES</b>
                  </h5>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table
                 sx={{ minWidth: 650 , fontSize:"0.875rem",
                 fontWeight: 400,
                 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                  <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          width: "20%",
                          borderRight:"none"
                        }}
                      >
                       <b>
                        Customer
                        </b> 
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" , borderRight:"none"}}>
                        {quotationAllData[0]?.companyName}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight:"none",
                        }}
                      >
                        <b>
                        Enquiry Date
                        </b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" }}>
                        {moment(quotationAllData[0]?.enquiryDate).format("DD/MM/YYYY")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          width: "20%",
                          borderRight:"none",
                        }}
                      >
                         <b>
                         Billing Address
                        </b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" ,borderRight:"none"}}>
                        {newAddressArray?.[0]?.address}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight:"none"
                        }}
                      >
                         <b>
                        Quotation Number | Date
                        </b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" }}>
                        {quotationAllData[0]?.quotationNumber} | {moment(quotationAllData[0]?.date).format("DD/MM/YYYY")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          width: "20%",
                          borderRight:"none"
                        }}
                      >
                        <b>
                        Contact Person
                        </b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" , borderRight:"none"}}>
                        {contact?.contname}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight:"none",
                          width: "22%",
                        }}
                      >
                        <b>
                        Contact Number
                        </b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%"}}>
                        {contact?.contactNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight:"none"
                        }}
                      >
                        <b>
                        E-mail
                        </b>
                      </TableCell>
                      <TableCell align="left" style={{borderRight:"none"}}>{contact?.email}</TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight:"none",
                        }}
                      >
                        <b>
                        Reference Number
                        </b>
                      </TableCell>
                      <TableCell align="left">
                      {quotationAllData[0]?.referenceNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight:"none",
                        }}
                      >
                       <b>Quotation Validity</b>
                      </TableCell>
                      <TableCell align="left" style={{borderRight:"none"}}>
                      {quotationAllData[0]?.quotationValidity}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight:"none",
                        }}
                      >
                        <b>
                        Equipment Delivery
                        </b>
                      </TableCell>
                      <TableCell align="left">
                        {quotationAllData[0]?.equipmentDelivery}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight:"none",
                        }}
                      >{
                        newAddressArray?.[0]?.shipping?.address?<b>Shipping Address</b>: ""
                      }
                       
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          borderRight: "none",
                        }}
                      >
                         {newAddressArray?.[0]?.shipping?.address}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "600",
                          backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b>
                        Equipment Collection
                        </b>
                      </TableCell>
                      <TableCell align="left">
                      {quotationAllData[0]?.equipmentCollection}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  textAlign: "left",
                  border: "1px solid black",
                  padding: "3px 10px",
                  display: "flex",
                  gap: "0px",
                  flexDirection: "column",
                  fontSize: "13px",
                }}
              >
                <b>Dear Sir / Madam</b>
                <span style={{ paddingLeft: "10px" }}>
                  Sub: {quotationAllData[0]?.subject}
                </span>
                <b style={{ fontWeight: 500 }}>
                  We sincerely thank you for the interest shown with our
                  Calibration services. We are happy to enclose detailed
                  techno-commercials as below mentioned ;
                </b>
              </div>

              <p style={{ textAlign: "center", color: "black" }}>
                {" "}
                <b>Charges towards calibration of following item:-</b>
              </p>

              <InstrumentTable
                classes={classes}
                quotationProductDetails={quotationProductDetails}
                productList1={productList}
                quotationAllData={quotationAllData}
              ></InstrumentTable>

                <div style={{fontSize:"13px",pageBreakInside: "avoid",paddingTop:"2px"}}>
                {/* <div className="font-link-footer">
                   <h6>
                    <b>Terms of Service</b>
                  </h6>
                  <div style={{ whiteSpace: "pre-wrap" ,padding:"1px 5px",textAlign:"justify"}}>
                    {quotationAllData[0]?.termsConditions || settings["terms and condition"]}
                  </div> 
                </div> */}
              <p style={{ textAlign: "center", color: "black" }}>
                We look forward to receive your valuable purchase order at the
                earliest.
              </p>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}

          <div
            style={{
              pageBreakBefore: "always",
            }}
          >
            <div
              style={{
                width: "95%",
                padding: "10px",
                margin: "20px auto",
                textAlign: "center",
              }}
            >
              <h4>TERMS & CONDITIONS</h4>
              <div
                style={{
                  border: "1px solid black",
                  margin: "20px auto",
                  padding: "10px",
                }}
              >
                <ol style={{ listStyleType: "decimal", textAlign: "left" }}>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    Order to be released in the name of “Precise Calibration
                    Lab, Pune“.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    Time Required for Calibration: 3-4 days.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    Operation Manuals with Specifications shall be submitted
                    along with the equipments.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    No jobs shall be taken up with out identification of serial
                    No or ID code.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    Kindly note that, except 230V, 50 Hz Power supply, we do not
                    use any external power supply like Batteries, Chargers, D.C
                    Power supply source etc. It should be provided by the
                    customer only.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    All Calibrations are done in SI Units only.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    This offer does not cover servicing, if the instruments are
                    not working and if it is possible to provide service to the
                    instrument the estimation charge for the same will be
                    provided to you after accessing the instruments.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    Calibration will be performed as per manufacturer’s
                    recommended procedure / lab approved procedure based on
                    standard measurement practices .
                  </li>

                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    Adequate packing shall be taken care by customer while
                    submitting the equipments to our representative or by
                    courier.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    Calibration will be restricted to the quantities specified
                    in Purchase Order.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    Payments shall be made in the form of Cheque /DD in favour
                    of "Precise Calibration Lab "payable at Pune. 12. Re-issue
                    of reports shall be on a chargeable basis.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    Equipments and Calibration Reports will be issued subject to
                    receipt of Payment.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    If we do not receive any feedback from your side, we assume
                    that above Terms are acceptable to you.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    <b>Tax:18% as per GST</b>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    Courier charges extra.
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    <b>
                      Any adopter if needed is extra cost of same will be
                      intimated for approval.
                    </b>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      paddingLeft: "20px",
                      textAlign: "justify",
                    }}
                  >
                    This quotation is valid for 30 days only
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </Paper>
    </>
  );
}
