// Use This for testing
const URL = "precise.bfiinfotech.co.in";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "ICICI Bank, Chakan Branch.",
  "Bank A/c. No.": "050805501033",
  "IFSC Code": "ICIC0000508",
};
export const GST_NUMBER = " 27GVKPK3764G1Z5";

export const DISABLED_MODULE =["Summary Report","Courier Register","History Card","Expenses","Master In/Out","Customer Complaints","Customer Feedback","Audit","Document Upload","User","Purchase"]; 

export const DEVICE_TYPE = ["desktop", "tablet", "mobile"];
