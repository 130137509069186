import React, { useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ClassicTable } from "../../../utils/components/Styles";
import moment from "moment";
import { DatasheetContext } from "./datasheet.context.js";

export default function DetailsOfCalStandards() {
  const {
    state: {
      standardMasterArray,
      selectedStandardIds,
      selectedStandardMasters,
    },
    dispatch,
  } = useContext(DatasheetContext);

  //validation Function
  const validateStandards = (type) => {
    let counter = 1;
    if (type === 1) {
      let expiredStandards = [];
      standardMasterArray.map((standard) => {
        if (moment().isAfter(standard.validUpto)) {
          expiredStandards.push(
            `${counter}. ${standard.stId} (${standard.standardName})`
          );
          counter = counter + 1;
        }
      });
      return expiredStandards;
    }
  };

  const updateSelectedStandardMasters = (selectedStandardIds) => {
    // fun: assign standard master data from standardMasterArray to selectedStandardMasters based on selectedStandardIds
    let _selectedStandardIds = selectedStandardIds
      .map((id) => Number(id))
      .filter((id) => id > 0);

    if (!standardMasterArray || standardMasterArray?.length === 0) return;

    let tempSelectedStandardMasters = [];
    _selectedStandardIds.forEach((id) => {
      let standardMaster = standardMasterArray.find(
        (standard) => standard.id == id
      );
      if (standardMaster) tempSelectedStandardMasters.push(standardMaster);
    });
    // set selectedStandardMasters
    dispatch({
      field: "selectedStandardMasters",
      value: [...tempSelectedStandardMasters],
    });
  };

  useEffect(() => {
    updateSelectedStandardMasters(selectedStandardIds);
  }, [selectedStandardIds, standardMasterArray]);

  useEffect(() => {
    if (standardMasterArray.length > 0) {
      let expiredStandards = validateStandards(1);
      if (expiredStandards.length > 0) {
        window.alert(
          `Below standards are expired:\n${expiredStandards.join(
            "\n"
          )} \n\nPlease recalibrate these standards and edit datasheet later!`
        );
      }
    }
  }, [standardMasterArray]);

  return (
    <div>
      <Typography variant="h6" component="div" sx={{ mb: 2 }}>
        Details of Calibration Standard Used
      </Typography>
      <div style={{ width: "100%", overflow: "auto" }}>
      <ClassicTable>
        <Table sx={{ minWidth: 660 }}  aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell>Name of Standard</TableCell>
              <TableCell>Range</TableCell>
              <TableCell>Accuracy</TableCell>
              <TableCell>Least Count</TableCell>
              <TableCell>Make/Model</TableCell>
              <TableCell>Certificate No.</TableCell>
              <TableCell>Tracability</TableCell>
              <TableCell>Cal.dt</TableCell>
              <TableCell>Due Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedStandardMasters &&
              selectedStandardMasters?.map((row, index) => (
                <TableRow key={"standard-row-" + index}>
                  <TableCell>{index + 1}</TableCell>

                  <TableCell
                    style={{
                      color: `${moment().isAfter(row?.validUpto) ? "red" : ""}`,
                    }}
                  >
                    {row.standardName}
                  </TableCell>
                  <TableCell>{row.masterrange}</TableCell>
                  <TableCell>{row.masteraccuracy}</TableCell>
                  <TableCell>{row.masterleastcount}</TableCell>
                  <TableCell>{row.make}</TableCell>
                  <TableCell>{row.certificateNumber}</TableCell>
                  <TableCell>{row.traceability}</TableCell>
                  <TableCell>
                    {moment(row.calDate).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>
                    {moment(row.validUpto).format("DD-MM-YYYY")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </ClassicTable>
      </div>
    </div>
  );
}
