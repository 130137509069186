import axios from "axios";
import { BASE_URL, SRF_ID_BASE } from "../../../global";
import { toast } from "react-toastify";
import { clearPreviousReadings } from "../helper";
import moment from "moment";

const processData = (settings, indicators, dispatch) => {
  let amd = false,
    calTypeSetting = false;

  let _settings = {};
  settings?.forEach((setting) => {
    _settings[setting.keyName] = setting.value;
    if (
      setting.keyName === "amendment" &&
      setting.value.toLowerCase() === "true"
    ) {
      amd = true;
    } else if (
      setting.keyName === "Calibration Type" &&
      setting.value.toLowerCase() === "true"
    ) {
      calTypeSetting = true;
    }
  });
  dispatch({
    field: "indicators",
    value: {
      ...indicators,
      isShowAmendment: amd,
      isCalTypeSetting: calTypeSetting,
      outOffRangeTracker: {},
    },
  });
  dispatch({
    field: "settings",
    value: _settings,
  });
};

export function fetchSettings(indicators, dispatch) {
  try {
    axios.get(BASE_URL + `settings`).then((res) => {
      processData(res.data, indicators, dispatch);
    });
  } catch (err) {
    toast.error("Failed to fetch settings");
    console.log("[ERROR] Failed to fetch settings, error: ", err);
  }
}

function prepareAndSetDatasheet(datasheetDetails, dispatch) {
  let range_low = [];
  let range_high = [];
  let ranges = datasheetDetails?.ranges?.split("||");
  ranges?.forEach((range) => {
    range_low.push((range.split("|")[0]?.match(/[+-\d\.]+/g) || [])[0]);
    range_high.push((range.split("|")[1]?.match(/[+-\d\.]+/g) || [])[0]);
  });

  // 2. calculate next due date if not present
  if (!datasheetDetails?.nextDueDate) {
    let calibrationDate = datasheetDetails.calibrationDate;

    let calibrationFrequency = datasheetDetails.calibrationFrequency;
    if (calibrationFrequency && calibrationDate) {
      datasheetDetails["nextDueDate"] = new Date(
        moment(calibrationDate)
          .add(calibrationFrequency?.split(" ")?.[0] || 0, "M")
          .format("YYYY-MM-DD")
      );
    }
  } else {
    datasheetDetails["nextDueDate"] = new Date(datasheetDetails.nextDueDate);
  }

  dispatch({
    field: "datasheetDetails",
    value: {
      ...datasheetDetails,
      range_low: range_low.join("||"),
      range_high: range_high.join("||"),
    },
  });
}

export async function fetchDatasheet(datasheetId, dispatch) {
  let url = BASE_URL;
  return axios
    .get(
      url +
        `xjoin?_join=ds.datasheets,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(ds.instrumentId,eq,ins.id)&_on2=(cust.id,eq,ds.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=ds.lastModified,ds.amendment,ds.revisionNumber,ds.calibratedby,ds.clientId,cust.companyName,ins.instrumentName,ds.jobNumber,ds.requestedname,ds.id,ds.make,ds.dcNumber,ds.dcDate,ds.lc,ds.instrumentId,ds.deletedTableIds,ins.standardMasterIds,ds.ranges,ins.calibrationProcedureNo,ins.referenceStandards,cust.address,ds.dcNumber,ds.calibrationDate,ds.dcDate,ds.nextDueDate,ds.receiptDate,ds.calProcRefNo,ds.mfgNo,ds.identificationNo,ds.startTemp,ds.endTemp,ds.additionalStandardIds,ds.DUCID,ds.calibrationType,ds.specificValues,ds.location,ds.serialNumber,ds.accuracy,ds.poNumber,ds.poDate,ds.dateOfIssue,ds.model,ds.ConOfDuc,ds.calPoint,ds.calMethod,ds.locationOfInstrument,ds.srnNo,ds.configuration,ds.atmosphericPressure,ds.tableDisplayStatus,ds.startHumidity,ds.endHumidity,disc.startTemp,disc.endTemp,disc.startHumidity,disc.endHumidity,disc.id,disc.name,disc.remarks,ds.extraColumns,ds.calibrationReason,ds.calFrequency&_where=(ds.id,eq, ${datasheetId})`
    )
    .then((res) => {
      if (res.data.length > 0 && res.data[0] != null) {
        let data = res.data[0];
        let datasheetDetails = {
          id: datasheetId,
          srfId: res.data[0].ds_jobNumber
            ? Number(res.data[0].ds_jobNumber.split("/")[0]) - SRF_ID_BASE
            : 0,
          jobNumber: data.ds_jobNumber,
          calibrationFrequency: data.ds_calFrequency || "",
          revisionNumber: data.ds_revisionNumber,
          clientId: data.ds_clientId,
          clientIdNumber: res.data[0].ds_clientId,
          clientName: res.data[0].cust_companyName,
          instrumentName: res.data[0].ins_instrumentName,
          requestedname: res.data[0].ds_requestedname
            ? res.data[0].ds_requestedname
            : "",
          instrumentId: res.data[0].ds_instrumentId,
          make: res.data[0].ds_make,
          lc: res.data[0].ds_lc,
          defaultRemark: res.data[0].disc_remarks || "",
          ranges: res.data[0].ds_ranges,
          dcDate: res.data[0].ds_dcDate ? res.data[0].ds_dcDate : "",
          dcNo: res.data[0].ds_dcNumber,
          deletedTableIds: res.data[0].ds_deletedTableIds,
          referenceStandards: res.data[0].ins_referenceStandards,
          calibrationProcedureNo: res.data[0].ins_calibrationProcedureNo,
          calibrationDate: res.data[0].ds_calibrationDate
            ? res.data[0].ds_calibrationDate
            : "",
          nextDueDate: res.data[0].ds_nextDueDate
            ? res.data[0].ds_nextDueDate
            : "",
          receiptDate: res.data[0].ds_receiptDate
            ? res.data[0].ds_receiptDate
            : "",
          mfgNo: res.data[0].ds_mfgNo,
          startTemp: res.data[0].ds_startTemp
            ? res.data[0].ds_startTemp
            : res.data[0].disc_startTemp,
          endTemp: res.data[0].ds_endTemp
            ? res.data[0].ds_endTemp
            : res.data[0].disc_endTemp,
          startHumidity: res.data[0].ds_startHumidity
            ? res.data[0].ds_startHumidity
            : res.data[0].disc_startHumidity,
          endHumidity: res.data[0].ds_endHumidity
            ? res.data[0].ds_endHumidity
            : res.data[0].disc_endHumidity,
          identificationNo: res.data[0].ds_identificationNo,
          DUCID: res.data[0].ds_DUCID,
          location: res.data[0].ds_location,
          accuracy: res.data[0].ds_accuracy,
          serialNo: res.data[0].ds_serialNumber,
          poNo: res.data[0].ds_poNumber,
          poDate: res.data[0].ds_poDate,
          dateOfIssue: res.data[0].ds_dateOfIssue,
          model: res.data[0].ds_model,
          conOfDuc: res.data[0].ds_ConOfDuc,
          calPoint: res.data[0].ds_calPoint,
          calMethod: res.data[0].ds_calMethod,
          locationOfInstrument: res.data[0].ds_locationOfInstrument,
          configuration: JSON.parse(res.data[0].ds_configuration) || {},
          atmosphericPressure: res.data[0].ds_atmosphericPressure,
          disciplineId: res.data[0].disc_id,
          disciplineName: res.data[0].disc_name,
          tableDisplayStatus: res.data[0].ds_tableDisplayStatus,
          extraColumns: Object.entries(JSON.parse(res.data[0].ds_extraColumns)),
          amendmentHistory: res.data[0].ds_amendment,
          calibrationReason: res.data[0].ds_calibrationReason,
        };

        prepareAndSetDatasheet(datasheetDetails, dispatch);

        dispatch({
          field: "standardMasterDetails",
          value: { standardMasterIds: data?.ins_standardMasterIds },
        });

        dispatch({
          field: "selectedStandardIds",
          value: data?.ds_additionalStandardIds?.split(",") || [],
        });

        // TODO: move both the below calls into separate function
        axios
          .get(
            BASE_URL +
              `srfs/${
                res.data[0].ds_jobNumber
                  ? Number(res.data[0].ds_jobNumber.split("/")[0]) - SRF_ID_BASE
                  : 0
              }?_fields=serviceReqNumber,entryDate,customerAnnextureFilePaths`
          )
          .then((res) => {
            dispatch({
              field: "srfDetails",
              value: {
                srfNumber: res.data[0]?.serviceReqNumber,
                entryDate: res.data[0]?.entryDate,
                customerAnnextureFilePaths:
                  res.data[0]?.customerAnnextureFilePaths,
              },
            });
          });

        if (res.data[0].ds_calibratedby) {
          axios
            .get(BASE_URL + `users/${res.data[0].ds_calibratedby}`)
            .then((res) => {
              const userData = res.data[0];
              const calibratedByUsername = userData?.userName || "";
              dispatch({
                field: "calibratedBy",
                value: calibratedByUsername,
              });
            })
            .catch((err) => console.error("Error fetching user data:", err));
        }
      }
    })
    .catch((err) => {
      console.error("datasheet data fetching error: ", err);
    });
}

export const fetchCertificateDetails = (datasheetId, dispatch) => {
  axios
    .get(
      BASE_URL + `certificates/${datasheetId}?_fields=certificateNumber,ULRNo`
    )
    .then((res) => {
      dispatch({
        field: "certificateDetails",
        value: {
          certificateNumber: res.data[0]?.certificateNumber,
          ULRNumber: res.data[0]?.ULRNo,
        },
      });
    });
};

export function fetchDatasheetStaticTables(
  instrumentId,
  deletedTableIds,
  setDerivedColumns,
  setUnceraintyConfig,
  dispatch,
  table_id
) {
  let url = BASE_URL;
  deletedTableIds = deletedTableIds?.split(",")?.map((id) => Number(id));
  let data_url = table_id
    ? `datasheetStaticTables/${table_id}`
    : `datasheetStaticTables?_where=(instrumentId,eq,${instrumentId})`;
  return axios
    .get(url + data_url)
    .then((res) => {
      if (deletedTableIds) {
        res.data = res.data.filter((row) => !deletedTableIds.includes(row.id));
      }
      if (res.data.length > 0) {
        let tmp = {};
        let cMap = {};
        res.data.map((table) => {
          tmp[table.id] = {
            isUncertainty: table.isUncertainty,
            isVerticalUncertainty: table.isVerticalUncertainty,
            isAutomated: table.isAutomated,
            defaultValues: JSON.parse(table.defaultConfiguration)?.values,
          };
          cMap[table.id] = Object.keys(
            JSON.parse(table.defaultConfiguration).formulas
          );
        });
        dispatch({
          field: "staticTables",
          value: res.data,
        });
        setDerivedColumns({ ...cMap });
        setUnceraintyConfig(tmp);
      } else {
        dispatch({
          field: "staticTables",
          value: [],
        });
        setDerivedColumns({});
        setUnceraintyConfig({});
      }
    })
    .catch((err) => {
      console.error(
        "[ERROR] Failed to fetch datasheet static tables, error: ",
        err
      );
      return err;
    });
}

export function fetchCertificateStaticTables(
  instrumentId,
  deletedTableIds = "",
  dispatch
) {
  let url = BASE_URL;
  return axios
    .get(
      url + `certificateStaticTables?_where=(instrumentId,eq,${instrumentId})`
    )
    .then((res) => {
      dispatch({
        field: "certificateStaticTables",
        value: res.data,
      });

      return res;
    })
    .catch((err) => {
      console.error("certificate static tables data fetching error: ", err);
      return err;
    });
}

export function fetchStaticReadingRows(datasheetId, dispatch, isAutoload=false, currentDatasheetId=0) {
  let url = BASE_URL;

  return Promise.all([
    // for static datasheet
    axios
      .get(
        url + `datasheetStaticReadings?_where=(datasheetId,eq,${datasheetId})`
      )
      .then((res) => {
        // if isAutoload is true, then change datasheetId to currentDatasheetId, and set id to 0
        if (isAutoload) {
          res.data = res.data.map((e) => {
            e.datasheetId = currentDatasheetId;
            e.id = 0;
            return e;
          });
        }
        dispatch({
          field: "datasheetStaticReadings",
          value: res.data,
        });
      })
      .catch((err) => {
        console.error(
          "[ERROR] Failed to load datasheet reading rows, error: ",
          err
        );
      }),

    // for static certificate
    axios
      .get(
        url +
          `certificateStaticReadings?_where=(certificateId,eq,${datasheetId})` // datasheet will always be same as certificateId
      )
      .then((res) => {
        
        // if isAutoload is true, then change datasheetId to currentDatasheetId, and set id to 0
        if (isAutoload) {
          res.data = res.data.map((e) => {
            e.certificateId = currentDatasheetId;
            e.id = 0;
            return e;
          });
        }

        dispatch({
          field: "certificateStaticReadings",
          value: res.data,
        });
      })
      .catch((err) => {
        console.error(
          "[ERROR] Failed to load certificate reading rows, error: ",
          err
        );
      }),
  ]);
}

// TODO: simplify this function
export async function fetchOtherStaticReadingRows(
  datasheetId,
  datasheetStaticReadings,
  datasheetDetails,
  dispatch
) {
  // 1. prepare query for fetching datasheet
  let queries = [];
  if (datasheetDetails?.DUCID)
    queries = queries.concat([
      `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId})~and(DUCID,eq,${datasheetDetails?.DUCID})`,
    ]);
  if (datasheetDetails?.ranges && datasheetDetails?.lc)
    queries = queries.concat([
      `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${
        datasheetDetails?.instrumentId
      })~and((ranges,like,${datasheetDetails?.ranges.replaceAll(
        "#",
        "_"
      )})~and(lc,like,${datasheetDetails?.lc.replaceAll("#", "_")}))`,
    ]);
  if (datasheetDetails?.models)
    queries = queries.concat([
      `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId})~and(model,ne,${datasheetDetails?.models})`,
    ]);
  if (datasheetDetails?.serialNo)
    queries = queries.concat([
      `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${datasheetDetails?.instrumentId})~and(serialNumber,ne,${datasheetDetails?.serialNo})`,
    ]);

  // 2. fetch datasheet having >1 readings
  let res = [];
  for (let i = 0; i < queries.length; i++) {
    res = await axios.get(BASE_URL + queries[i]);
    if (res.data.length > 0) break;
  }

  // 3. pick random datasheetId from the fetched datasheets
  let datasheetIds = res.data?.map((row) => row.id);
  let _datasheetId = 0
  if (datasheetIds?.length > 0) {
    _datasheetId =
      datasheetIds[Math.floor(Math.random() * datasheetIds.length)];

    // 4. clear previous readings
    let readingIds = datasheetStaticReadings
      .filter((e) => e.datasheetId == datasheetId)
      .map((row) => row.id);
    if (readingIds.length > 0)
      await clearPreviousReadings("datasheetStaticReadings", readingIds);
    readingIds = datasheetStaticReadings
      .filter((e) => e.datasheetId == datasheetId)
      .map((row) => row.id);
    if (readingIds.length > 0)
      await clearPreviousReadings("certificateStaticReadings", readingIds);
  
    // 5. fetch readings for the selected datasheet
    fetchStaticReadingRows(_datasheetId, dispatch, true, datasheetId);
  }
}

function fetchStandardRanges(ids = [], dispatch) {
  const convertStandardData = {
    pl_id: "id",
    pl_stId: "stId",
    pl_standardName: "title",
    pr_id: "rangeId",
    pr_rangeName: "range",
    pr_accuracy: "accuracy",
    pr_gravity: "lc",
    pr_axialUniformity: "axialUniformity",
    pr_radialUniformity: "radialUniformity",
    pr_stability: "stability",
    pl_type: "type",
    pl_masterleastcount: "masterleastcount",
    pr_mode: "mode",
    pr_parameter: "parameter",
    pr_paratype: "paratype",
    ds_disciplineKey: "dsKey",
  };

  axios
    .get(
      BASE_URL +
        `xjoin?_join=pl.standards,_j,pr.standardRanges,_j,ds.discipline&_on1=(pl.id,eq,pr.standardId)&_on2=(pl.disciplineId,eq,ds.id)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId,pr.rangeName,pr.accuracy,pr.gravity,pr.axialUniformity,pr.radialUniformity,pr.mode,pr.parameter,pr.paratype,pr.stability,pl.type,pl.masterleastcount,pl.make,pl.traceability,pl.calDate,pl.validUpto,ds.disciplineKey&_where=(pl.status,eq,1)~and(pl.id,in,${ids[0]})`
      // 1. fetch standard masters
    )
    .then((res) => {
      let standardRangesData = [];
      res.data.map((d) => {
        standardRangesData.push({});
        Object.entries(d).map(
          (e) =>
            (standardRangesData[standardRangesData.length - 1][
              convertStandardData[e[0]]
            ] = e[1])
        );
      });
      standardRangesData = standardRangesData.filter((st) => st.type == 1);
      dispatch({
        field: "standardRanges",
        value: standardRangesData,
      });
    })
    .catch((err) => {
      console.error("standards data fetching error: ", err);
    });

  // 2. fetch supportive standards
  axios
    .get(
      BASE_URL +
        `xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId,pr.rangeName,pr.accuracy,pr.gravity,pr.axialUniformity,pr.radialUniformity,pr.mode,pr.parameter,pr.paratype,pr.stability,pl.type,pl.masterleastcount,pl.make,pl.traceability,pl.calDate,pl.validUpto&_where=(pl.status,eq,1)~and(pl.id,in,${ids[1]})`
    )
    .then((res) => {
      let supportiveStandardMasterData = [];
      res.data.map((d) => {
        supportiveStandardMasterData.push({});
        Object.entries(d).map(
          (e) =>
            (supportiveStandardMasterData[
              supportiveStandardMasterData.length - 1
            ][convertStandardData[e[0]]] = e[1])
        );
      });
      supportiveStandardMasterData = supportiveStandardMasterData.filter(
        (st) => st.type == 2
      );
      dispatch({
        field: "supportiveStandardMasterArray",
        value: supportiveStandardMasterData,
      });
    })
    .catch((err) => {
      console.error("standards data fetching error: ", err);
    });
}

export function fetchUnits(disciplineId, dispatch) {
  return axios
    .get(
      BASE_URL +
        "unit?_where=(status,eq,1)" +
        (disciplineId ? `~and(disciplineId,eq,${disciplineId})` : "")
    )
    .then((res) => {
      let unitSymbols = res.data.reduce((prev, current) => {
        return [...prev, current.symbol];
      }, []);
      unitSymbols = [...new Set(unitSymbols)];

      dispatch({
        field: "units",
        value: res.data,
      });

      dispatch({
        field: "unitSymbols",
        value: unitSymbols,
      });
    })
    .catch((err) => {
      console.error("unit fetching error: ", err);
      return err;
    });
}

export function fetchCmcReadings(instrumentId, setCmcReadings) {
  return axios
    .get(BASE_URL + `cmc?_where=(instrumentId,eq,${instrumentId})`)
    .then((res) => {
      setCmcReadings(res.data);
    })
    .catch((err) => {
      console.error("Something Went Wrong while fetching standardRanges!");
      return err;
    });
}

// instrumentDetails section
// TODO: combine below 2 functions into 1 react hook
const filterAllowedMasters = async (mst) => {
  let userType = Number(localStorage.getItem("type"));
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];

  if (userType !== 2) {
    return mst;
  }
  if (!mst) return mst;
  mst = (mst || "").split(",").map((e) => e.split(":")[0]);
  if (mst === "") return mst;
  let res = null;
  res = await axios.get(
    BASE_URL +
      `standardInOut?_where=(status,eq,1)~and(personName,eq,${
        user[1]
      })~and(expectedReturnDate,gt,${moment(new Date()).format("YYYY-MM-DD")})`
  );
  let standardInOutData = res.data
    .filter((d) => d.purpose.toLowerCase() != "self-calibration")
    .map((e) => e.standards)
    .join(",")
    .split(",");

  if (res.data.length) {
    mst = mst.filter((c) => standardInOutData.includes(c));
  }
  return mst.join(",");
};

export function fetchInstrument(instrumentId, dispatch) {
  let url = BASE_URL;
  return axios
    .get(url + `instruments?_where=(id,eq,${instrumentId})`)
    .then(async (res) => {
      if (res.data.length > 0) {
        dispatch({
          field: "instrumentDetails",
          value: res.data[0],
        });

        // extract standard ids
        let rawStandardIds = await filterAllowedMasters(
          res.data[0]?.standardMasterIds
        );
        let standardIds = rawStandardIds
          .split(",")
          .map((value) => value.split(":")[0]);

        let rawSupportiveInstrumentMasterData =
          res.data[0]?.supportiveInstrumentMasterData || "";
        let supportiveInstrumentIds = rawSupportiveInstrumentMasterData
          .split(",")
          .map((value) => value.split(":")[0]);

        if (standardIds.length > 0 || supportiveInstrumentIds.length > 0)
          fetchStandardRanges(
            [standardIds.join(","), supportiveInstrumentIds.join(",")],
            dispatch
          );
      }
      return res;
    })
    .catch((err) => {
      console.error("instrumentDetails data fetching error: ", err);
      return err;
    });
}

// TODO: combine this function with fetchStandard
export async function fetchAllowedStandards(standardMasterIds, dispatch) {
  let combineArray = await filterAllowedMasters(standardMasterIds);
  return axios
    .get(BASE_URL + `standards?_where=(id,in,${combineArray.toString()})`)
    .then((res) => {
      dispatch({
        field: "standardMasterArray",
        value: [...res?.data],
      });

      return res;
    });
}

export function getReferenceData(datasheetId, dispatch) {
  let tables = ["srfInstruments"];
  let newReferenceData = {};
  axios.get(`${BASE_URL}${tables[0]}/${datasheetId}`).then((res) => {
    newReferenceData[`${tables[0]}`] = res.data[0];
    dispatch({
      field: "referenceData",
      value: {
        ...newReferenceData,
      },
    });
  });
}

export const fetchDisciplineDetails = (id, dispatch) => {
  axios
    .get(
      BASE_URL +
        `xjoin?_join=ds.datasheets,_j,ins.instruments,_j,disc.discipline&_on1=(ds.instrumentId,eq,ins.id)&_on2=(ins.disciplineId,eq,disc.id)&_fields=disc.documentNumber,disc.issueNo,disc.issueDate,disc.amendmentNo,disc.amendmentDate&_where=(disc.id,eq,${id})`
    )
    .then((res) => {
      dispatch({
        field: "disciplineDetails",
        value: {
          documentNumber: res.data[0]?.disc_documentNumber || "",
          issueNo: res.data[0]?.disc_issueNo || "",
          issueDate: res.data[0]?.disc_issueDate || "",
          amendmentNo: res.data[0]?.disc_amendmentNo || "",
          amendmentDate: res.data[0]?.disc_amendmentDate || "",
        },
      });
    });
};
