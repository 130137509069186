import axios from "axios";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import { makeChunks } from "../helper/helpers";
import unitConvertor from "../../utils/components/unitConvertor";

const tablesData = {};

const tablesMap = {
  datasheet: "datasheets",
  instrument: "instruments",
  datasheeetStaticTable: "datasheetStaticTables",
  datasheetStaticReading: "datasheetStaticReadings",
  standard: "standards",
  standardRange: "standardRanges",
  supportiveInstrumentRange: "instruments",
  settings: "settings",
  // datasheetTemplate,
  // cmcs,
};

// helper function
function limitPrecision(number, maxPrecision = 7) {
  const numString = number.toString();
  const decimalIndex = numString.indexOf(".");

  // If the number has fewer than maxPrecision digits, return the original number
  if (decimalIndex === -1 || numString.length <= maxPrecision) {
    return number;
  }

  // Use toFixed to limit precision to maxPrecision decimal places
  const limitedNumber = Number(number.toFixed(maxPrecision));

  return limitedNumber;
}

function findTokens(str) {
  const regex = /\$[\w.:'' ]+/g;
  const matches = str?.match(regex);

  if (matches) {
    return matches;
  } else {
    return [];
  }
}

const fetchTables = async (queries) => {
  let chunks = makeChunks(Object.entries(queries));

  for (let chunk of chunks) {
    await Promise.all(
      chunk.map((e) =>
        axios.get(e[1]).then((res) => ({ name: e[0], value: res.data[0] }))
      )
    )
      .then((res) => {
        res.map((e) => (tablesData[e.name] = e.value));
      })
      .catch((err) => {
        console.error("err : ", err);
      });
  }
  return;
};

export const generateTableData = async (
  unceratinty,
  readings,
  tableId,
  datasheetId,
  instrumentId,
  uncertaintiesMap
) => {
  let conditions = unceratinty.map((e) => [e.id, JSON.parse(e.showcondition)]);
  let configs = unceratinty.map((e) => [e.id, JSON.parse(e.sourceconfig)]);
  let sensitiveCoefficientFormulas = unceratinty.map((e) => [
    e.id,
    JSON.parse(e.sensitives),
  ]);
  let standards = readings.map((reading) => reading.standardRanges);
  let supportives = readings.map((reading) => reading.supportiveRanges);
  let readingIds = readings.map((reading) => reading.id);
  let tmp = {};
  let tables = {};
  let attributeTables = {};
  let idMaps = {
    settings: null,
    datasheet: datasheetId,
    instrument: instrumentId,
    datasheeetStaticTable: tableId,
    reading: readingIds.map((rdng, i) => ({
      datasheetStaticReading: rdng,
      masters: standards[i].map((std) => ({
        standard: std[0],
        standardRange: std[1],
      })),
      supportiveInstrument: supportives[i][0],
      supportiveInstrumentRange: supportives[i][1],
    })),
  };

  let tokens = {};
  let tp = [];

  conditions.map((c, i) => {
    tokens = [
      ...findTokens(Object.entries(c[1]).flat().join(",")),
      ...findTokens(Object.entries(configs[i][1]).flat().join(",")),
      ...findTokens(
        Object.entries(sensitiveCoefficientFormulas[i][1]).flat().join(",")
      ),
    ];
    tokens = tokens
      .map((token) => {
        tp = token.substring(1).split(".");
        tp[2] = tp[2]?.split(":");
        return tp;
      })
      .map((e, i) => {
        if (!tablesMap[e[0]]) {
          toast.warning("found wrong table in equation : " + tokens[i]);
          return null;
        }
        if (e[2]) {
          attributeTables[
            `${BASE_URL}${tablesMap[e[0]]}?_where=(${
              e[2][0]
            },eq,${e[2][1]?.replaceAll("'", "")})&_fields=${e[1]}`
          ] = [e[1]];
          return null;
        } else if (idMaps[e[0]]) {
          let id = idMaps[e[0]];
          let tId = `${BASE_URL}${tablesMap[e[0]]}_${id}`;
          if (tables[tId]) tables[tId][0][e[1]] = true;
          else tables[tId] = [{ [e[1]]: true }, `${e[0]}_${id}`, id];
          return null;
        }
        return e;
      })
      .filter((e) => e);
    if (tokens?.length > 0) tmp[c[0]] = tokens;
  });

  let tId = "";
  let id = "";
  readingIds.map((_, i) => {
    standards[i].map((std, j) => {
      Object.keys(tmp).map((t) => {
        if (uncertaintiesMap[std[0]]?.includes(t)) {
          tmp[t].map((e) => {
            id = idMaps.reading[i][e[0]]
              ? idMaps.reading[i][e[0]]
              : idMaps.reading[i].masters[j][e[0]];
            tId = `${BASE_URL}${tablesMap[e[0]]}_${id}`;
            if (tables[tId]) {
              tables[tId][0][e[1]] = true;
            } else {
              tables[tId] = [{ [e[1]]: true }, `${e[0]}_${id}`, id];
            }
            if (e[0] == "standardRange" && !tables[tId][0]["rangeName"]) {
              tables[tId][0]["rangeName"] = true;
            }
          });
        }
      });
    });
  });

  let queries = {};
  let tc = "";
  Object.entries(attributeTables).map((e) => (queries[e[1]] = e[0]));
  Object.entries(tables).map((e) => {
    queries[e[1][1]] = `${e[0].split("_")[0]}?_fields=${Object.keys(
      e[1][0]
    ).join(",")}&_where=(id,eq,${e[1][2]})`;
  });
  await fetchTables(queries);
};

export const convertUnit = (val, from, to, baseValue=null) => {
  let res = val;
  val = val || 0;
  if (from && to) {
    if (from.trim() === "%" && baseValue != null) {
      res = (Number(val) * Number(baseValue)) / 100;
    } else if (to.trim() === "%" && baseValue != null) {
      res = (Number(val) * 100) / Number(baseValue);
    } else {
      res = unitConvertor(val, from, to);
    }
  }

  return res;
};

const resolveToken = (token, selectors) => {
  token = token.trim();
  let tp = token.substring(1).split(".");
  tp[2] = tp[2]?.split(":");
  if (!tp[2]) {
    token = `${tp[0]}_${selectors[tp[0]]}`;
  }
  return tablesData[token]?.[tp[1]];
};

export const check_percentage = (unit, rl, rh, cp) => {
  if (unit === "%FSD") return [cp];
  else if (unit === "%RDG") return [rl, rh];
  else return [];
};

export const resolveCondition = (conditions, selectors) => {
  let trueCondition = "default";
  for (const element of conditions) {
    let condition = element;
    if (condition == "default") continue;
    let updatedCondition = condition;
    let tokens = findTokens(condition).filter((token) => token != "default");
    for (const element of tokens) {
      let token = element;
      let tokenResult = resolveToken(token, selectors);
      if (/\D/.test(tokenResult) || tokenResult == "")
        tokenResult = `'${tokenResult}'`;
      updatedCondition = updatedCondition.replace(token, tokenResult);
    }

    let res = Function("return " + updatedCondition)();
    if (res === true) {
      trueCondition = condition;
      break;
    }
  }
  return trueCondition;
};

export const resolveFormula = (
  sourceFormula,
  updatedFormula,
  fixedUnit,
  selectors,
  fallbackUnit,
  cp
) => {
  let ranges,
    V = [];

  if (updatedFormula !== "") {
    let tokens = findTokens(sourceFormula);

    // 1. iterate tokens and resolve values, units
    let TokenValues = {};
    for (let i = 0; i < tokens?.length; i++) {
      let rawValue = resolveToken(tokens[i], selectors);
      let value = rawValue?.split("#")[0] || 0;
      let unit = rawValue?.split("#")[1] || "";
      TokenValues[tokens[i]] = {
        value: value,
        unit: unit,
      };
    }

    // 2. find desired unit for final uncertainty factor value
    // process: if tokenValues containes different unit types then consider fallbackUnit as desired unit, else consider unit from tokenValues
    let desiredUnit = fixedUnit;
    if (!desiredUnit) {
      let units = Object.values(TokenValues).map((e) => e.unit);
      units = units.filter((e) => e !== "");
      units = [...new Set(units)];
      desiredUnit = units?.length > 1 ? fallbackUnit : units[0];
    }

    // 3. replace token with respective values
    for (let i = 0; i < tokens?.length; i++) {
      let value = TokenValues[tokens[i]]?.value || 0;
      let unit = TokenValues[tokens[i]]?.unit || "";

      if (unit && fallbackUnit) {
        if (
          sourceFormula.includes("standardRange") &&
          (unit === "%FSD" ||
            unit === "%RDG" ||
            fallbackUnit === "%FSD" ||
            fallbackUnit === "%RDG")
        ) {
          ranges = resolveToken("$standardRange.rangeName", selectors)
            ?.split("|")
            .map((e) => e?.split("#"));
          V =
            check_percentage(unit, ranges[0][0][0], ranges[1][0][0], cp[0]) ||
            check_percentage(
              fallbackUnit,
              ranges[0][0][0],
              ranges[1][0][0],
              cp[0]
            );
        }
        value = convertUnit(value, unit, desiredUnit, V[0], V[1]);
      }

      updatedFormula = updatedFormula?.replace(tokens[i], value);
    }
    // 4. evaluate formula
    let resultValue = Function("return " + updatedFormula)();

    // 5. limit precision to 7 digits and add unit
    resultValue = limitPrecision(resultValue || 0, 7);
    resultValue = String(resultValue) + "#" + (desiredUnit || "");

    return resultValue;
  }
};

export const resolveUncertaintyValue = (
  unceratinty,
  selectors,
  fallbackUnit,
  cp
) => {
  // 1. resolve condition and process source config
  // find values from table references
  let config = JSON.parse(unceratinty.sourceconfig);
  let sourceValueUnit = unceratinty.sourceValueUnit;
  let selectedCondition = resolveCondition(Object.keys(config), selectors);
  let sourceFormula = config[selectedCondition];
  let updatedFormula = sourceFormula;
  let sourceConfigRes = resolveFormula(
    sourceFormula,
    updatedFormula,
    sourceValueUnit,
    selectors,
    fallbackUnit,
    cp
  );

  // 2. resolve and process sensitive coefficient
  // find values from table references
  let sensitiveCoefficient = JSON.parse(unceratinty.sensitives);
  let sensitiveCoefficientUnit = unceratinty.sensitiveCoefficientUnit;
  selectedCondition = resolveCondition(
    Object.keys(sensitiveCoefficient),
    selectors
  );
  sourceFormula = sensitiveCoefficient[selectedCondition];
  updatedFormula = sourceFormula;
  let sensitiveCoefficientRes = resolveFormula(
    sourceFormula,
    updatedFormula,
    sensitiveCoefficientUnit,
    selectors,
    fallbackUnit,
    cp
  );

  return [sourceConfigRes, sensitiveCoefficientRes || 1];
};

export const calculateUncertaintyContribution = (
  ufLimitXi,
  probabiltyDistribution,
  sensitiveCoefficient,
  precisionCount,
  desiredUnit,
  baseValue
) => {
  // 1. calculate uncertaintyContribution
  let [ufLimitXiValue, ufLimitXiUnit] = ufLimitXi?.split("#");
  let [sensitiveCoefficientValue, sensitiveCoefficientUnit] =
    sensitiveCoefficient?.split("#");
  let uncertaintyContribution = Number(
    (ufLimitXiValue / probabiltyDistribution) * sensitiveCoefficientValue
  ).toFixed(precisionCount || 4);

  // 2. convert value to desired unit
  let fromUnit =
    sensitiveCoefficientUnit !== "" ? sensitiveCoefficientUnit : ufLimitXiUnit;
  uncertaintyContribution = convertUnit(
    uncertaintyContribution,
    fromUnit,
    desiredUnit,
    baseValue
  );
  return String(uncertaintyContribution + `#${desiredUnit}`);
};
